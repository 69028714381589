import UIBox, { BoxProps as UIBoxProps } from '@mui/material/Box'
import { SPACING } from 'shared/theme'
import styled, { css } from 'styled-components'

import { containerQuery } from '../../helpers/utils'
import { breakpoints } from '../../theme/breakpoints'

const StyledBox = styled(UIBox)<{ $xsMt?: number; $smMt?: number }>`
  ${(p) =>
    p.$smMt &&
    containerQuery(
      breakpoints.sm,
      css`
        margin-top: ${SPACING * p.$smMt}px;
      `
    )}

  ${(p) =>
    p.$xsMt &&
    containerQuery(
      breakpoints.xs,
      css`
        margin-top: ${SPACING * p.$xsMt}px;
      `
    )}
`

export type BoxProps = { xsMt?: number; smMt?: number } & UIBoxProps

export const Box = ({ xsMt, smMt, ...rest }: BoxProps) => <StyledBox {...rest} $xsMt={xsMt} $smMt={smMt} />
