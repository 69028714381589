import axios from 'axios'
import FileSaver from 'file-saver'
import * as HttpStatus from 'http-status-codes'
import queryString from 'query-string'
import { parseAppLoadDetails } from 'shared/helpers/load'
import { PaginatedResults } from 'shared/helpers/typeHelper'
import { toSnakeCase } from 'shared/helpers/utils'
import { isAttachment } from 'shared/models/document'
import { QueryFilter } from 'shared/models/job'

import { EquipmentType } from '../models/equipment'
import {
  BookmarkedLoad,
  BrokerSelfServeJobTracking,
  ContactedLoad,
  Job,
  JobDetail,
  Load,
  LoadDetail,
  LoadStopLoadingType,
  LoadStopType,
  LoadStopTypeDetail,
  UpdateDocumentPayload,
  UploadDocumentPayload,
} from '../models/jobs'
import { OfferPayload } from '../models/payloads'
import { NonPremiumExternalBookRateCon } from '../pages/ExternalBook/NonPremiumExternalBook/NonPremiumExternalBook.constants'

export function parseUserSubmittedBids(job: Job) {
  // TODO: remove this once the backend is updated
  return {
    user_submitted_bid_min:
      typeof job.user_submitted_bid_min === 'string'
        ? parseFloat(job.user_submitted_bid_min)
        : job.user_submitted_bid_min,
    user_submitted_bid_max:
      typeof job.user_submitted_bid_max === 'string'
        ? parseFloat(job.user_submitted_bid_max)
        : job.user_submitted_bid_max,
  }
}

export function fetchLoad(id: string): Promise<LoadDetail> {
  let url = `/api/v1/loads/${id}/`
  return axios.get(url).then((response) => {
    if (response.status === HttpStatus.OK) {
      return parseAppLoadDetails(response.data as Load)
    }
    throw response
  })
}

export function queryLoads(payload: QueryFilter, async?: boolean, loadIdForReload?: string) {
  let query = {
    origin_location: payload.origin_location,
    origin_range_mi__max: payload.origin_range_mi__max,
    origin_pickup_date__min: payload.origin_pickup_date__min,
    dest_location: payload.dest_location,
    dest_range_mi__max: payload.dest_range_mi__max,
    equipment: payload.equipment,
    total_pay__min: payload.total_pay__min,
    trip_rate__min: payload.trip_rate__min,
    sort_type: payload.sort_type,
    booking_type: payload.booking_type,
    trip_distances: payload.trip_distances,
    load_providers:
      payload.load_providers && payload.load_providers.length ? payload.load_providers : undefined,
    masked_data: payload.masked_data,
    age_min__min: payload.age_min__min,
    age_min__max: payload.age_min__max,
    truck_weight_lb__max: payload.truck_weight_lb__max,
    requested_states: payload.requested_states,
    // TODO: remove this once the backend is updated to always return the offline_book flag correctly
    is_offline_book_compatible: true,
  }

  const url = loadIdForReload
    ? `/api/v2/query_for_reloads?load_id=${loadIdForReload}`
    : `/api/v2/query_loads${async ? '_async' : ''}`

  return axios.post(url, query).then((response) => {
    if (response.status === HttpStatus.OK) {
      // in async result there's only channel_name and async results have prex_scores
      if (async) return response.data

      if (loadIdForReload) return { results: response.data.loads }

      // for non-async results, only shown when users with old-sync-search enabled
      const prexScores = (response.data.prex_scores ?? {}) as Record<string, number>

      return {
        ...response.data,
        results: response.data.results.map((res: any) => ({
          ...res,
          prex_scores: prexScores[res.id] ?? 0,
        })),
      }
    }
  })
}

export function createBookmark(load_id: string): Promise<BookmarkedLoad> {
  let url = `/api/v1/loads/${load_id}/bookmark/`
  return axios.post(url, { id: load_id }).then((response) => {
    if (response.status === HttpStatus.CREATED) {
      return {
        ...response.data,
        load: parseAppLoadDetails(response.data.load),
      }
    }
  })
}

export function fetchBookmarkedLoads(page = 1): Promise<PaginatedResults<BookmarkedLoad>> {
  let url = `/api/v1/bookmarked-loads/?page=${page}`
  return axios.get(url).then((response) => {
    if (response.status === HttpStatus.OK) {
      return {
        ...response.data,
        results: response.data.results.map((r: any) => ({
          ...r,
          load: parseAppLoadDetails(r.load),
        })),
      }
    }
  })
}

export function fetchBookmarkedLoadByLoadId(loadId: string): Promise<BookmarkedLoad | null> {
  let url = `/api/v1/bookmarked-loads/${loadId}/fetch-by-load-id/`
  return axios.get(url).then((response) => {
    if (response.status === HttpStatus.OK) {
      return response.data
    }
    return null
  })
}

export function deleteBookmark(bookmarkload_id: string) {
  let url = `/api/v1/bookmarked-loads/${bookmarkload_id}/`
  return axios.delete(url).then((response) => {
    if (response.status === HttpStatus.NO_CONTENT) {
      return
    }
  })
}

export interface GetJobsOptions {
  assignedTo?: string
}

export function getJobs(page = 1, options: GetJobsOptions = {}): Promise<PaginatedResults<JobDetail>> {
  const params = queryString.stringify(
    toSnakeCase({
      page,
      updated_status_details: true,
      ...options,
    })
  )

  return axios.get(`/api/v1/jobs/?${params}`).then((response) => {
    if (response.status === HttpStatus.OK) {
      return {
        ...response.data,
        results: response.data.results.map((r: any) => ({
          ...r,
          ...parseUserSubmittedBids(r),
          load: parseAppLoadDetails(r.load),
        })),
      }
    }
  })
}

export function getJob(jobid: string): Promise<JobDetail> {
  let url = `/api/v1/jobs/${jobid}/`
  return axios.get(url, { params: { updated_status_details: true } }).then((response) => {
    if (response.status === HttpStatus.OK) {
      return {
        ...response.data,
        ...parseUserSubmittedBids(response.data),
        load: parseAppLoadDetails(response.data.load),
      }
    }
  })
}

export function reloadQuery(load_id: string, filterOptions: QueryFilter) {
  let url = `/api/v1/loads/${load_id}/reload_query/`
  let payload = { filter_options: filterOptions }
  return axios
    .post(url, payload)
    .then((response) => {
      if (response.status === HttpStatus.OK) {
        return response.data
      }
    })
    .catch((err) => {
      throw err.response
    })
}

export function bookLoad(loadId: string, payload: OfferPayload): Promise<Job> {
  let url = `/api/v1/loads/${loadId}/book/`
  return axios
    .post(url, payload, { params: { updated_status_details: true } })
    .then((response) => response.data)
    .catch((err) => {
      throw err.response
    })
}

interface BidStarterLoadPayload {
  bid_amount: number
  assigned_to: string
}

export function bidStarterLoad(loadId: string, payload: BidStarterLoadPayload): Promise<Job> {
  let url = `/api/v1/loads/${loadId}/instant_bid/`
  return axios
    .post(url, payload)
    .then((response) => response.data)
    .catch((err) => {
      throw err.response
    })
}

export interface ExternalLoadPayloadWithoutStop {
  broker: string
  source_human_id?: string
  dest_location: string
  dest_date: string
  origin_location: string
  origin_date: string
  trip_rate: string
  assigned_to: string
  include_stops: false
  assigned_truck?: string
  create_lane_template?: boolean
  lane_template_name?: string
  lane_template?: string
}

export interface ExternalLoadPayloadWithStops {
  broker: string
  company_name?: string
  source_human_id?: string
  truck_weight_lb?: string
  equipment?: EquipmentType[]
  trip_rate: string
  assigned_to: string
  include_stops: true
  stops: ExternalLoadStopPayload[]
  assigned_truck?: string
  create_lane_template?: boolean
  lane_template_name?: string
  lane_template?: string
}

export interface ExternalLoadStopPayload {
  type?: LoadStopType
  loading_type?: LoadStopLoadingType | ''
  type_detail: LoadStopTypeDetail
  date_start: string
  date_end: string
  location_address1?: string
  location_address2?: string
  location_city: string
  location_name?: string
  location_state: string
  location_zip?: string
  notes?: string
  sequence_number: number
}

export type ExternalLoadPayload = ExternalLoadPayloadWithoutStop | ExternalLoadPayloadWithStops

export function bookExternalLoad(payload: ExternalLoadPayload): Promise<Job> {
  const { include_stops, ...data } = payload
  const url = `/api/v1/jobs/external/?include_stops=${include_stops || false}`

  return axios.post(url, data).then((response) => {
    if (response.status === HttpStatus.CREATED) {
      return response.data
    }
    throw response
  })
}

export function feasibleBooking(load_id: string) {
  let url = `/api/v1/loads/${load_id}/flags/`
  return axios.get(url, { params: { booking: true } }).then((response) => {
    if (response.status === HttpStatus.OK) {
      return response.data
    }
  })
}

export function uploadFile(payload: UploadDocumentPayload) {
  let config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }
  let url = `/api/v1/jobs/${payload.jobId}/upload/`
  return axios.post(url, payload.formData, config).then((response) => {
    if (response.status === HttpStatus.CREATED) {
      return response.data
    }
  })
}

export function downloadFile(url: string, fileName: string, mimeType: string) {
  return axios({ url: url, method: 'GET', responseType: 'blob' })
    .then((response) => {
      if (response.status === HttpStatus.OK) {
        const blob = new Blob([response.data], { type: mimeType })
        FileSaver.saveAs(blob, fileName)
      }
    })
    .catch((err) => {
      throw err.response
    })
}

export function deleteFile(id: string) {
  let url = `/api/v1/documents/${id}/`
  return axios.delete(url).then((response) => {
    if (response.status === HttpStatus.NO_CONTENT) {
      return
    }
  })
}

export function updateFile(payload: UpdateDocumentPayload) {
  let url = `/api/v1/documents/${payload.docId}/`
  let config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }

  return axios.patch(url, payload.attachments, config).then((response) => {
    if (response.status === HttpStatus.OK) {
      return response.data
    }
  })
}

export interface ParsedLocation {
  locationTimezone: string
  locationTimezoneName: string
  date: string
}

export function parseLocation(payload: { date: Date | string; address: string }): Promise<ParsedLocation> {
  const url = `/api/v1/parse-location/`

  return axios.post(url, payload).then((response) => {
    return {
      locationTimezone: response.data.location_timezone,
      locationTimezoneName: response.data.location_timezone_name,
      date: response.data.date,
    }
  })
}

export interface UpdateNonFactoringJobPayload {
  assigned_to?: string
  confirmed_rate?: string
  load?: {
    broker?: string
    source_human_id?: string
    truck_weight_lb?: string
    company_name?: string
    equipment?: EquipmentType[]
  }
  stops?: ExternalLoadStopPayload[]
  assigned_truck?: string
}

export function updateNonFactoringJob(job_id: string, payload: UpdateNonFactoringJobPayload) {
  const url = `/api/v1/jobs/${job_id}/nf-update/`
  return axios.post(url, payload).then((response) => response.data)
}

export interface UpdateFactoringJobPayload {
  assigned_to?: string
  assigned_truck?: string
}

export function updateFactoringJobAssignment(job_id: string, payload: UpdateFactoringJobPayload) {
  const url = `/api/v1/jobs/${job_id}/assignment-change/`
  return axios.post(url, payload).then((response) => response.data)
}

export function completeJob(job_id: string) {
  let url = `/api/v1/jobs/${job_id}/complete/`
  return axios.post(url, { id: job_id }).then((response) => {
    if (response.status === HttpStatus.OK) {
      return response.data
    }
  })
}

export function completeNonFactoringJob(job_id: string) {
  let url = `/api/v1/jobs/${job_id}/nf-complete/`
  return axios.post(url, { id: job_id }).then((response) => {
    if (response.status === HttpStatus.OK) {
      return response.data
    }
  })
}

export function deleteJob(job_id: string) {
  let url = `/api/v1/jobs/${job_id}/`
  return axios.delete(url).then((response) => {
    if (response.status === HttpStatus.OK) {
      return response.data
    }
  })
}

export function cancelJob(job_id: string) {
  return axios.post(`/api/v1/jobs/${job_id}/cancel/`).then((r) => r.data)
}

export function requestCancelationJob(job_id: string) {
  return axios.post(`/api/v1/jobs/${job_id}/request-cancellation/`).then((r) => r.data)
}

export function removeNonFactoredJob(job_id: string) {
  return axios.post(`/api/v1/jobs/${job_id}/nf-remove/`)
}

export function createContactedLoad(loadId: string) {
  let url = `/api/v1/loads/${loadId}/contact/`
  return axios.post(url, { id: loadId }).then((response) => {
    if (response.status === HttpStatus.CREATED) {
      return response.data
    }
  })
}

export function removeContactedLoad(contactedLoadId: string) {
  let url = `/api/v1/contacted-loads/${contactedLoadId}/not-interested/`
  return axios.post(url)
}

export function bookContactedLoad(
  contactedLoadId: string,
  assignedTo: string,
  confirmedRate?: string,
  assignedTruck?: string
): Promise<Job> {
  let url = `/api/v1/contacted-loads/${contactedLoadId}/book/`
  const data = {
    assigned_to: assignedTo,
    ...(confirmedRate && { confirmed_rate: confirmedRate }),
    ...(assignedTruck && { assigned_truck: assignedTruck }),
  }
  return axios.post(url, data).then((response) => response.data)
}

export function fetchContactedLoad(contactedLoadId: string): Promise<ContactedLoad> {
  let url = `/api/v1/contacted-loads/${contactedLoadId}/`
  return axios.get(url).then((response) => {
    if (response.status === HttpStatus.OK) {
      return response.data
    }
  })
}

export function fetchContactedLoadByLoadId(loadId: string): Promise<ContactedLoad | null> {
  let url = `/api/v1/contacted-loads/${loadId}/fetch-by-load-id/`
  return axios.get(url).then((response) => {
    if (response.status === HttpStatus.OK) {
      return response.data
    }
    return null
  })
}

export function getBrokerSelfServeJobTracking(brokerLoadId: string): Promise<BrokerSelfServeJobTracking> {
  return axios.get(`/api/v1/job-tracking/${brokerLoadId}/`).then((response) => response.data)
}

interface SendJobTrackingEmailPayload {
  email: string
  message: string
}

export function sendJobTrackingEmail(jobId: string, formData: SendJobTrackingEmailPayload) {
  return axios.post(`/api/v1/jobs/${jobId}/share-location/`, formData).then((response) => response.data)
}

export function fetchContactedLoads(page = 1): Promise<PaginatedResults<ContactedLoad>> {
  let url = `/api/v1/contacted-loads/?page=${page}`
  return axios.get(url).then((response) => {
    if (response.status === HttpStatus.OK) {
      return {
        ...response.data,
        results: response.data.results.map((r: any) => ({
          ...r,
          load: parseAppLoadDetails(r.load),
        })),
      }
    }
  })
}

export interface Provider {
  display_name: string
  is_shipper: boolean
  name: string
}

export function fetchProviders() {
  return axios.get('/api/v1/providers/').then((response) => {
    if (response.status === HttpStatus.OK) {
      return response.data as Provider[]
    }
  })
}

export interface RateConParsedResponse {
  id: string
  rate_con_fields: RateConParsedData
}

export interface RateConParsedData {
  num_parsed_fields: number
  parsed_fields: RateConParsedFields
}

export interface RateConParsedFields {
  source_human_id: string
  broker: string // broker id
  broker_email: string
  trip_rate: number
  truck_weight_lb: number
  truck_length_ft: number
  equipment: EquipmentType[]
  contact_name: string
  contact_phone: string
  stops: RateConParsedStop[]
}

export interface RateConParsedStop {
  date_end: string
  date_start: string
  location_address1: string
  location_address2: string
  location_city: string
  location_name: string
  location_state: string
  location_zip: string
  notes: string
  sequence_number: number
  type_detail: LoadStopTypeDetail
  loading_type?: LoadStopLoadingType
}

export const PARSE_RATE_CON_ERROR =
  'We could not auto-fill your document. Please enter your load information manually.'

export function parseRateConfirmation(
  file: NonNullable<NonPremiumExternalBookRateCon>
): Promise<RateConParsedResponse> {
  const url = '/api/v3/parse-rate-confirmation/'
  const config = { headers: { 'Content-Type': 'multipart/form-data' } }
  const payload = new FormData()
  payload.append('rate_confirmation_file', isAttachment(file) ? file.file : file)

  return axios.post(url, payload, config).then((response) => response.data)
}

export interface PreviewJobCopiesPayload {
  new_dates: string[]
}

export function previewJobCopies(
  id: string,
  payload: PreviewJobCopiesPayload
): Promise<{ new_start_date: string; job: Job }[]> {
  return axios.post(`/api/v1/jobs/${id}/preview-duplicate-jobs/`, payload).then((res) => res.data)
}

export interface CreateJobCopiesPayload {
  new_stops_date_time: {
    date_start: string
    date_end: string
  }[][]
}

export function createJobCopies(id: string, payload: CreateJobCopiesPayload) {
  return axios.post(`/api/v1/jobs/${id}/create-duplicate-jobs/`, payload)
}

interface NotifyFactoringCompany {
  email: string
  document_ids: string[]
}

export function notifyFactoringCompany(jobId: string, payload: NotifyFactoringCompany) {
  return axios.post(`/api/v1/jobs/${jobId}/notify-factoring-company/`, payload)
}
