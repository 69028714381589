import { Breakpoint, useMediaQuery } from '@mui/material'
import { useEffect, useRef, useState } from 'react'

import { breakpoints, breakpointsSize } from '../theme/breakpoints'

export function useBreakpoint(breakpoint: Breakpoint) {
  return useMediaQuery(breakpoints[breakpoint])
}

export function useContainerBreakpoint(breakpoint: typeof breakpointsSize[keyof typeof breakpointsSize]) {
  const wrapRef = useRef<HTMLDivElement | null>(null)
  const [isMounted, setIsMounted] = useState(false)
  const [windowSize, setWindowSize] = useState<number>(window.innerWidth)
  const [container, setContainer] = useState<HTMLElement | null>(null)

  useEffect(() => {
    if (wrapRef.current && !container) {
      setContainer(getClosestContainer(wrapRef.current))
    }
  }, [isMounted, container, wrapRef])

  useEffect(() => {
    setIsMounted(true)
    const calculateSize = () => setWindowSize(window.innerWidth)
    window.addEventListener('resize', calculateSize, { passive: true })
    return () => window.removeEventListener('resize', calculateSize)
  }, [])

  if (container) {
    const result = [wrapRef, breakpoint > container.getBoundingClientRect().width] as const
    return result
  }

  const result = [wrapRef, breakpoint > windowSize] as const
  return result
}

function getClosestContainer(elem: HTMLElement) {
  function match(elem: HTMLElement) {
    if (getComputedStyle(elem, '').containerType === 'inline-size') return elem
    return null
  }

  // Get the closest matching element
  // @ts-ignore
  for (; elem && elem !== document; elem = elem.parentNode) {
    if (match(elem)) return elem
  }
  return null
}
