import { camelCase, mapKeys } from 'lodash'
import { useEffect, useState } from 'react'
import { FieldErrors, UseFormSetError } from 'react-hook-form'
import { useDispatch } from 'react-redux'
import { showErrorBanner } from 'shared/actions/ui/actions'
import { BannerProps } from 'shared/components/Context/UserContext'
import { parseError } from 'shared/helpers/errors'
import { scrollToErrors, useScrollToErrors } from 'shared/hooks/useScrollToErrors'
import { setErrors as setErrorFromObject } from 'shared/hooks/useSetErrors'

export function useErrorMessage() {
  const dispatch = useDispatch()

  // TODO: Fix the type of e in the components that use this hook
  return function errorMessage(e: BannerProps | any) {
    const bannerProps: BannerProps = {
      // TODO: @taylangocmen - fix the type of e in the components that use this hook
      ...(e?.message && typeof e.message === 'string' && !e.message.toLowerCase?.().includes('request failed')
        ? { message: e.message, title: e.title }
        : { message: parseError(e) }),
      actionLabel: e?.actionLabel,
      action: e?.action,
      footer: e?.footer,
      actionRenderer: e?.actionRenderer,
      autoHideDuration: e?.autoHideDuration,
    }

    dispatch(showErrorBanner(bannerProps))
  }
}

export function useErrorMessageWithScrollToErrors({
  setFormErrors,
  formErrors,
}: {
  setFormErrors?: UseFormSetError<any>
  formErrors?: FieldErrors<any>
} = {}) {
  const errorMessage = useErrorMessage()
  const [errors, setErrors] = useState<Record<string, any> | null>(null)

  useScrollToErrors(formErrors || {})

  useEffect(() => {
    if (setFormErrors) {
      setErrorFromObject(errors, setFormErrors)
    }
  }, [errors, setFormErrors])

  function handleError(err: any) {
    if (err?.response?.data) {
      const camelCaseErrors = mapKeys(err?.response?.data, (_, k) => camelCase(k))
      const firstError = Object.keys(camelCaseErrors)[0]

      if (firstError && firstError !== '0') {
        scrollToErrors(camelCaseErrors)
        setErrors(camelCaseErrors)
        if (document.querySelector(`#${firstError}`)) {
          return
        }
      }
    }
    errorMessage(err)
  }

  return { handleError, errors }
}
