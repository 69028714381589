import ErrorIcon from '@mui/icons-material/Error'
import React, { useEffect, useRef, useState } from 'react'
import { useSelector } from 'react-redux'
import { Relative } from 'shared/components/Base/Base'
import { Box } from 'shared/components/Box/Box'
import { ExternalLink } from 'shared/components/ExternalLink/ExternalLink'
import { Text } from 'shared/components/Text/Text'
import { UserAvatar } from 'shared/components/UserAvatar/UserAvatar'
import { Handler } from 'shared/helpers/typeHelper'
import { toCamelCase } from 'shared/helpers/utils'
import { User as SharedUser } from 'shared/models/user'
import { blueGray, darkGray, errorD1, lightGray1, midGray } from 'shared/theme/colors'
import styled from 'styled-components'

import { SparklesGrey } from '../../components/Icons/Icon'
import { TypingIcon } from '../../components/Icons/TypingIcon'
import { ConsultantConversationMessage, ConsultantConversationMessageWidget } from '../../models/consultant'
import { currentUserSelector } from '../../redux/selectors/users'
import { ConsultantMessageToolbar } from './ConsultantMessageToolbar'

interface ConsultantMessageProps {
  consultant: string
  message: ConsultantConversationMessage
  isLast: boolean
  scrollToBottom: Handler
}

export function ConsultantMessage({ consultant, message, scrollToBottom, isLast }: ConsultantMessageProps) {
  const user = useSelector(currentUserSelector)
  const messageRef = useRef<HTMLDivElement>(null)
  const [textHeight, setTextHeight] = useState(0)

  useEffect(() => {
    setTextHeight(messageRef.current?.offsetHeight || 0)
  }, [message])

  function onToolbarOpen() {
    if (isLast) {
      scrollToBottom()
    }
  }

  function getTextColor() {
    if (message.isError) return errorD1
    if (message.isPending) return lightGray1
    return darkGray
  }

  function renderWidget(widget: ConsultantConversationMessageWidget) {
    if (widget.name === 'link') {
      return (
        <ExternalLink variant="label2" href={widget.parameters.url}>
          {widget.parameters.text}
        </ExternalLink>
      )
    }
    return null
  }

  return (
    <Message key={message.id}>
      {message.isFromUser ? (
        <UserAvatar user={toCamelCase(user) as SharedUser} width="32px" height="32px" fontSize="11px" />
      ) : (
        <ConsultantAvatar>
          <img src={SparklesGrey} alt="" width="14px" />
        </ConsultantAvatar>
      )}
      <Relative style={{ width: '100%' }}>
        <Text
          variant="label2"
          fontColor={getTextColor()}
          ref={messageRef}
          mt={textHeight > 30 ? 0 : 0.7}
          styles="white-space: pre-line;"
        >
          {message.text} {message.isError ? <StyledErrorIcon /> : null}
        </Text>
        {message.widgets?.map(renderWidget)}
        {!message.isFromUser && (
          <ConsultantMessageToolbar consultant={consultant} message={message} onToolbarOpen={onToolbarOpen} />
        )}
      </Relative>
    </Message>
  )
}

export function ConsultantMessageTyping() {
  return (
    <Message>
      <ConsultantAvatar>
        <img src={SparklesGrey} alt="" width="14px" />
      </ConsultantAvatar>
      <div>
        <Box mt={2} />
        <TypingIcon />
      </div>
    </Message>
  )
}

const Message = styled.div`
  display: flex;
  gap: 12px;
  margin-bottom: 24px;
  position: relative;
`

const ConsultantAvatar = styled.div`
  width: 32px;
  min-width: 32px;
  height: 32px;
  min-height: 32px;
  border-radius: 50%;
  background: ${midGray};
  border: 2px solid ${blueGray};
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledErrorIcon = styled(ErrorIcon)`
  font-size: 18px;
  color: ${errorD1};
  position: relative;
  top: 3px;
`
