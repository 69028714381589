export enum CacheKeys {
  CHECKLIST = 'CHECKLIST',
  CARDHOLDER = 'CARDHOLDER',
  CARDHOLDERS = 'CARDHOLDERS',
  BANK_ACCOUNTS = 'BANK_ACCOUNTS',
  BILLING = 'BILLING',
  BILLING_PERIOD = 'BILLING_PERIOD',
  CASH_BALANCE = 'CASH_BALANCE',
  CARD_ACTIVITY = 'CARD_ACTIVITY',
  ACCOUNT_ACTIVITY_PATH = 'ACCOUNT_ACTIVITY_PATH',
  ELIGIBLE_CARDHOLDERS = 'ELIGIBLE_CARDHOLDERS',
  CASH_SUMMARY = 'CASH_SUMMARY',
  CONTACTED_LOADS = 'CONTACTED_LOADS',
  BOOKMARKED_LOADS = 'BOOKMARKED_LOADS',
  STRIPE_SUBSCRIPTION = 'STRIPE_SUBSCRIPTION',
  STRIPE_SUBSCRIPTION_QUANTITY_PREVIEW = 'STRIPE_SUBSCRIPTION_QUANTITY_PREVIEW',
  STRIPE_SUBSCRIPTION_SESSION_INFO = 'STRIPE_SUBSCRIPTION_SESSION_INFO',
  DRIVING_ACCIDENTS = 'DRIVING_ACCIDENTS',
  DRIVING_VIOLATIONS = 'DRIVING_VIOLATIONS',
  EMPLOYMENT_HISTORY = 'EMPLOYMENT_HISTORY',
  EMPLOYMENT_HISTORY_ITEM = 'EMPLOYMENT_HISTORY_ITEM',
  LOAD_DETAIL = 'LOAD_DETAIL',
  JOB_DETAIL = 'JOB_DETAIL',
  JOB_COPY_PREVIEW = 'JOB_COPY_PREVIEW',
  SELF_SERVE_JOB_TRACKING = 'SELF_SERVE_JOB_TRACKING',
  BOOKMARKED_LOAD_DETAIL = 'BOOKMARKED_LOAD_DETAIL',
  CONTACTED_LOAD_DETAIL = 'CONTACTED_LOAD_DETAIL',
  JOBS = 'JOBS',
  JOB_TEMPLATE = 'JOB_TEMPLATE',
  JOBS_TEMPLATES = 'JOBS_TEMPLATES',
  INTEGRATIONS = 'INTEGRATIONS',
  PROVIDERS = 'PROVIDERS',
  GET_USER = 'GET_USER',
  TEAM_LEVEL_PERMISSIONS = 'TEAM_LEVEL_PERMISSIONS',
  SAFETY_DASH = 'SAFETY_DASH',
  SAVED_SEARCHES = 'SAVED_SEARCHES',
  SUBSCRIPTION_CANCEL_REASONS = 'SUBSCRIPTION_CANCEL_REASONS',
  TRUCK = 'TRUCK',
  TRUCKS = 'TRUCKS',
  TRAILER = 'TRAILER',
  TRAILERS = 'TRAILERS',
  ELD_INTEGRATION_STATUS = 'ELD_INTEGRATION_STATUS',
  FOR_RELOAD_QUERY = 'FOR_RELOAD_QUERY',
  PAYROLL_MEMBERS = 'PAYROLL_MEMBERS',
  PAYROLL_TRANSFERS = 'PAYROLL_TRANSFERS',
  ASTRA_USER = 'ASTRA_USER',
  REPORT_QUARTERS = 'REPORT_QUARTERS',
  BACKGROUND_CHECK = 'BACKGROUND_CHECK',
  REVENUE_CALCULATOR = 'REVENUE_CALCULATOR',
  FUEL_CARD_LIMITS = 'FUEL_CARD_LIMITS',
  FUEL_CARD_ACCOUNT_VALID = 'FUEL_CARD_ACCOUNT_VALID',
  LEASING_PARTNER_CONFIG = 'LEASING_PARTNER_CONFIG',
}
