import ArrowDropDownIcon from '@mui/icons-material/ArrowDropDown'
import ArrowDropUpIcon from '@mui/icons-material/ArrowDropUp'
import React from 'react'
import { Button, ButtonProps } from 'shared/components/Button/Button'
import styled from 'styled-components'

import { DropDownOpener, DropDownOpenerProps } from './DropdownOpener'

interface DropdownButtonProps<T> extends Omit<DropDownOpenerProps<T>, 'children'> {
  buttonProps?: Partial<ButtonProps>
}

const ArrowDropUp = styled(ArrowDropUpIcon)`
  margin: -2px -10px 0 0;
`

const ArrowDropDown = styled(ArrowDropDownIcon)`
  margin: -2px -10px 0 0;
`

export function DropdownButton<T>({ buttonProps, label, value, options, ...props }: DropdownButtonProps<T>) {
  function renderCaret(isOpen: boolean) {
    if (isOpen) {
      return <ArrowDropUp />
    }

    return <ArrowDropDown />
  }

  return (
    <DropDownOpener value={value} options={options} {...props}>
      {(isOpen) => (
        <Button size="medium" variant="secondary" {...buttonProps} icon={renderCaret(isOpen)} iconOnRight>
          {value ? options.find((o) => o.value === value)?.label || label : label}
        </Button>
      )}
    </DropDownOpener>
  )
}
