import queryString from 'query-string'

import { QueryFilter } from '../models/job'
import { SortType } from '../models/search'

function parseIfString(params: queryString.ParsedQuery<string>, key: string): Partial<QueryFilter> | {} {
  if (typeof params[key] === 'string') {
    return { [key]: params[key] }
  }

  return {}
}

function parseIfInt(params: queryString.ParsedQuery<string>, key: string): Partial<QueryFilter> | {} {
  if (typeof params[key] === 'string' && !isNaN(parseInt(params[key] as string))) {
    return { [key]: parseInt(params[key] as string) }
  }

  return {}
}

function parseIfStringArr(params: queryString.ParsedQuery<string>, key: string): Partial<QueryFilter> | {} {
  if (typeof params[key] === 'string' && (params[key] as string) !== '') {
    return { [key]: (params[key] as string).split(',') }
  }

  return {}
}

function parseIfBoolean(params: queryString.ParsedQuery<string>, key: string): Partial<QueryFilter> | {} {
  if (typeof params[key] === 'string') {
    return { [key]: params[key] === 'true' }
  }

  return {}
}

function parseIfSortType(params: queryString.ParsedQuery<string>, key: string): Partial<QueryFilter> | {} {
  if (typeof params[key] === 'string' && Object.keys(SortType).includes(params[key] as string)) {
    return { [key]: params[key] as SortType }
  }

  return {}
}

export const parseUrlParamsForSearch = (searchStr: string): Partial<QueryFilter> => {
  const params = queryString.parse(searchStr)

  return {
    ...parseIfString(params, 'origin_location'),
    ...parseIfInt(params, 'origin_range_mi__max'),
    ...parseIfString(params, 'origin_pickup_date__min'),
    ...parseIfString(params, 'dest_location'),
    ...parseIfInt(params, 'dest_range_mi__max'),
    ...parseIfStringArr(params, 'equipment'),
    ...parseIfInt(params, 'total_pay__min'),
    ...parseIfInt(params, 'trip_rate__min'),
    ...parseIfSortType(params, 'sort_type'),
    ...parseIfString(params, 'booking_type'),
    ...parseIfInt(params, 'trip_distance_mi__max'),
    ...parseIfInt(params, 'trip_distance_mi__min'),
    ...parseIfStringArr(params, 'trip_distances'),
    ...parseIfString(params, 'date_queried'),
    ...parseIfStringArr(params, 'load_providers'),
    ...parseIfInt(params, 'age_min__min'),
    ...parseIfInt(params, 'age_min__max'),
    ...parseIfInt(params, 'truck_weight_lb__max'),
    ...parseIfStringArr(params, 'requested_states'),
    ...parseIfBoolean(params, 'is_offline_book_compatible'),
    ...parseIfBoolean(params, 'async'),
  }
}
