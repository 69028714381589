import { parseAppLoadDetails } from 'shared/helpers/load'
import { parseUrlParamsForSearch } from 'shared/helpers/search'
import { initialQueryFilter, QueryFilter } from 'shared/models/job'

import { Job, JobsFilters, JobStatus, Load } from 'models/jobs'
import { SavedSearchFilter } from '../actions/jobs/actions'
import * as types from '../actions/jobs/actionTypes'

export interface jobState {
  queryMade: boolean
  queryResult: Load[]
  asyncChannel: string | null
  asyncQueryLoading: boolean
  queryFilter: QueryFilter
  savedSearchBeingEdited: SavedSearchFilter | null
  jobs: Job[]
  jobsFilter: JobsFilters
  pendingJobs: Job[]
  pendingJobsFilter: JobsFilters
}

let initState: jobState = {
  queryMade: false,
  queryResult: [],
  asyncChannel: null,
  asyncQueryLoading: false,
  queryFilter: {
    ...initialQueryFilter,
    ...parseUrlParamsForSearch(window.location.search),
  },
  savedSearchBeingEdited: null,
  jobsFilter: { status: JobStatus.CURRENT },
  pendingJobsFilter: { status: JobStatus.PENDING },
  pendingJobs: [],
  jobs: [],
}

// TODO: Remove default export
// eslint-disable-next-line import/no-default-export
export default function jobsReducer(state: jobState = initState, action: any) {
  switch (action.type) {
    case types.SET_QUERY_MADE:
      return {
        ...state,
        queryMade: action.queryMade,
      }
    case types.SET_QUERY:
      return { ...state, query: action.query }
    case types.SET_ASYNC_QUERY_CHANNEL:
      return {
        ...state,
        asyncChannel: action.asyncChannel,
      }
    case types.SET_ASYNC_QUERY_LOADING:
      return {
        ...state,
        asyncQueryLoading: action.asyncQueryLoading,
      }
    case types.SET_ASYNC_QUERY_RESULTS:
      return {
        ...state,
        queryResult: action.loads,
      }
    case types.ADD_ASYNC_QUERY_RESULTS:
      return {
        ...state,
        queryResult: [...state.queryResult, ...action.loads.map(parseAppLoadDetails)],
      }
    case types.SET_QUERY_RESULTS:
      return { ...state, queryResult: action.loads.map(parseAppLoadDetails) }
    case types.UPDATE_LOAD:
      let loads = state.queryResult.map((load) => (load.id === action.load.id ? { ...action.load } : load))
      return { ...state, queryResult: loads }
    case types.UPDATE_FILTER:
      return { ...state, queryFilter: action.filter }
    case types.SET_SAVED_SEARCH_BEING_EDITED:
      return { ...state, savedSearchBeingEdited: action.savedSearchBeingEdited }
    case types.SET_JOBS:
      return { ...state, jobs: action.jobs }
    case types.UPDATE_JOBS_FILTER:
      return { ...state, jobsFilter: action.filter }
    case types.UPDATE_PENDING_JOBS_FILTER:
      return { ...state, pendingJobsFilter: action.filter }
    case types.SET_PENDING_JOBS:
      return { ...state, pendingJobs: action.pendingJobs }
    case types.SAVE_DOCUMENT:
      return {
        ...state,
        jobs: state.jobs.map((job) =>
          job.id === action.document.job
            ? {
                ...job,
                documents: [...job.documents!, action.document],
              }
            : job
        ),
      }
    case types.REMOVE_DOCUMENT:
      return {
        ...state,
        jobs: state.jobs.map((job) =>
          job.id === action.jobId
            ? {
                ...job,
                documents: job.documents!.filter((doc) => doc.id !== action.docId),
              }
            : job
        ),
      }
    case types.UPDATE_DOCUMENT:
      return {
        ...state,
        jobs: state.jobs.map((job) =>
          job.id === action.document.job
            ? {
                ...job,
                documents: job.documents!.map((doc) =>
                  doc.id === action.document.id ? action.document : doc
                ),
              }
            : job
        ),
      }
    default:
      return state || initState
  }
}
