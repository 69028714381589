import KeyboardArrowLeft from '@mui/icons-material/KeyboardArrowLeft'
import KeyboardArrowRight from '@mui/icons-material/KeyboardArrowRight'
import { IconButton } from '@mui/material'
import Dialog from '@mui/material/Dialog'
import MuiDialogContent from '@mui/material/DialogContent'
import * as React from 'react'
import { DialogCard, WideWrap } from 'shared/components/Base/Base'
import { Handler } from 'shared/helpers/typeHelper'
import styled, { css } from 'styled-components'

import { useBreakpoint } from '../../hooks/useBreakpoint'
import { muiTheme } from '../../theme'
import { breakpoints } from '../../theme/breakpoints'
import { blueGray, dividerGray, greyf6 } from '../../theme/colors'
import { CloseButton } from '../CloseButton/CloseButton'

export const DIALOG_BOX_SHADOW = '0px 8px 8px rgba(0, 44, 177, 0.01)'

interface SimpleDialogProps {
  isOpen: boolean
  onClose?: Handler
  hideCloseButton?: boolean
  children: React.ReactNode
  className?: string
  hideBackdrop?: boolean
  alignToTop?: boolean
  fullScreen?: boolean
  fullScreenWithCard?: boolean
  xsFullScreen?: boolean
  maxWidth?: string
  hasSmallPadding?: boolean
  hasBigPadding?: boolean
  hasRemovedPaddings?: boolean
  isVisuallyHidden?: boolean
  overflow?: string
  size?: 'small' | 'medium'
  alignText?: 'left' | 'right' | 'center'
  backgroundColor?: string
}

const StyledDialog = styled(Dialog)<{ $isVisuallyHidden?: boolean }>`
  ${(p) => p.$isVisuallyHidden && `display: none;`}
`

const DialogContent = styled(MuiDialogContent)<{
  $alignText?: SimpleDialogProps['alignText']
  $hasCloseButton?: boolean
  $hasSmallPadding?: boolean
  $hasBigPadding?: boolean
  $hasRemovedPaddings?: boolean
  $fullScreen?: boolean
  $overflow?: string
}>`
  text-align: center;
  margin-top: ${muiTheme.spacing(2)};
  padding: ${muiTheme.spacing(6)};
  ${(p) => p.$alignText && `text-align: ${p.$alignText};`}
  ${(p) => p.$fullScreen && `padding: 20px; margin: 0; display: flex;`}
  ${(p) => p.$hasSmallPadding && `padding: 40px;`}
  ${(p) =>
    p.$hasBigPadding && `padding: ${muiTheme.spacing(5)}px ${muiTheme.spacing(8)} ${muiTheme.spacing(7)};`}
  ${(p) => p.$hasRemovedPaddings && `padding: 0;`}
  ${(p) => p.$overflow && `overflow: ${p.$overflow};`}

  @media ${breakpoints.xs} {
    padding: ${muiTheme.spacing(4)};
    ${(p) => p.$hasBigPadding && `padding: ${muiTheme.spacing(4)}`}
    ${(p) => p.$hasSmallPadding && `padding: 20px;`}
    ${(p) => p.$hasRemovedPaddings && `padding: 0;`}
  }
`

const StyledCloseButton = styled(CloseButton)`
  position: absolute;
  right: ${muiTheme.spacing(1)};
  top: ${muiTheme.spacing(1)};
`

export function SimpleDialog({
  onClose,
  hideCloseButton = false,
  children,
  isOpen,
  className = '',
  hideBackdrop = false,
  fullScreen,
  fullScreenWithCard,
  alignToTop,
  alignText,
  hasSmallPadding,
  hasBigPadding,
  hasRemovedPaddings,
  maxWidth,
  size,
  xsFullScreen,
  isVisuallyHidden,
  backgroundColor,
  overflow,
}: SimpleDialogProps) {
  const isSmMobile = useBreakpoint('sm')
  const isXsMobile = useBreakpoint('sm')
  const alignToTopStyle = { style: { alignItems: 'flex-start', marginTop: isSmMobile ? '40px' : '128px' } }
  function calculateWidth() {
    if (size === 'small') return '472px'
    if (size === 'medium') return '648px'
    return undefined
  }

  const paperProps = {
    style: {
      boxShadow: DIALOG_BOX_SHADOW,
      backgroundColor: backgroundColor || '',
      width: calculateWidth(),
      overflow,
      maxWidth,
      ...(isXsMobile && xsFullScreen
        ? {
            maxHeight: '100%',
            height: '100%',
            maxWidth: '100%',
            width: '100%',
            borderRadius: 0,
            boxShadow: 'none',
            margin: 0,
          }
        : null),
    },
  }
  if (fullScreen || fullScreenWithCard) {
    paperProps.style.backgroundColor = greyf6
  }

  function renderCard() {
    return (
      <WideWrap display="flex">
        <DialogCard $centerAlign>{children}</DialogCard>
      </WideWrap>
    )
  }

  return (
    <StyledDialog
      fullScreen={fullScreen || fullScreenWithCard}
      onClose={onClose}
      open={isOpen}
      hideBackdrop={hideBackdrop}
      PaperProps={paperProps}
      TransitionProps={alignToTop ? alignToTopStyle : undefined}
      $isVisuallyHidden={isVisuallyHidden}
    >
      {onClose && !hideCloseButton && <StyledCloseButton onClose={onClose} />}
      <DialogContent
        $overflow={overflow}
        $alignText={alignText}
        $hasCloseButton={Boolean(onClose)}
        $fullScreen={fullScreen || fullScreenWithCard}
        $hasSmallPadding={hasSmallPadding}
        $hasBigPadding={hasBigPadding}
        $hasRemovedPaddings={hasRemovedPaddings}
        className={className}
      >
        {fullScreenWithCard ? renderCard() : children}
      </DialogContent>
    </StyledDialog>
  )
}

const arrowButton = css`
  height: 40px;
  width: 40px;
  position: absolute;
  background: ${blueGray};
  color: white;
  top: 50%;
  transform: translate(0, -50%);

  &:hover {
    background: ${dividerGray};
  }

  &svg {
    height: 24px;
    width: 24px;
  }
`

const RightArrowButton = styled(IconButton)`
  ${arrowButton};
  right: -70px;

  @media ${breakpoints.xs} {
    right: -50px;
    top: 70%;
  }
`

const LeftArrowButton = styled(IconButton)`
  ${arrowButton};
  left: -70px;

  @media ${breakpoints.xs} {
    left: -50px;
    top: 70%;
  }
`

export function SimpleDialogArrowButton({
  onClick,
  position,
}: {
  onClick: Handler
  position: 'left' | 'right'
}) {
  if (position === 'right') {
    return (
      <RightArrowButton onClick={onClick}>
        <KeyboardArrowRight />
      </RightArrowButton>
    )
  }

  return (
    <LeftArrowButton onClick={onClick}>
      <KeyboardArrowLeft />
    </LeftArrowButton>
  )
}
