export const white = '#ffffff'
export const black = '#000000'
export const whitef7 = '#f7fcfd'

export const darkRoyalBlue = '#02258e'
export const paleblue = '#ebfbfe'
export const bluefa = '#d4f4fa'

export const lightgrey = '#f2f2f2'
export const lightgreye3 = '#e3e8ec'
export const darkgrey = '#bebfc6'
export const grey4d = '#4d4d4d'
export const greye0 = '#e0e0e0'
export const grey97 = '#979797'
export const greyc4 = '#c4c4c4'
export const greyf6 = '#f4f5f6'
export const greycb = '#a9bacb'

export const red = '#ec001b'
export const green = '#00c17c'
export const orange = '#ef9f03'
export const paleyellow = '#fff3c8'

// New names

// Gray
export const primaryBlack = '#101013'
export const darkGray = '#313137'
export const midGray = '#555962'
export const lightGray1 = '#8a8f9d'
export const lightGray2 = '#9fa3af'
export const dividerGray = '#c7d1da'
export const lightGray4 = '#e3e8ec'
export const bgGray = '#f4f7f9'

// Secondary
export const royalBlue = '#002cb1'
export const cyan = '#03cdef'
export const cyanD1 = '#00bae3'
export const kellyGreen = '#01b474'
export const kellyGreenD1 = '#00aa6d'
export const mangoYellow = '#f6b100'
export const marigoldOrange = '#e48900'
export const green2 = '#60d15e'

// Utility
export const success = '#d1f5e8'
export const successD1 = kellyGreenD1
export const warning = paleyellow
export const warningD1 = marigoldOrange
export const errorL1 = '#fff6f2'
export const error = '#fce6db'
export const errorD1 = '#cc0033'
export const informationL1 = '#e8fbff'
export const information = '#d4f4fa'
export const informationD1 = '#9bdae5'
export const blueGray = '#a9bac8'
export const sidebarGray = '#e8edf0'

// Form
export const formFill = bgGray
export const formStroke = dividerGray
