import React, { useCallback } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { useParams } from 'react-router'
import { BannerProps, UserProvider } from 'shared/components/Context/UserContext'
import { parseError } from 'shared/helpers/errors'
import { LoggedInUser } from 'shared/models/user'

import { useUser } from '../../hooks/query/useUser'
import { useErrorMessage } from '../../hooks/useErrorMessage'
import { useCanViewRates, usePermission } from '../../hooks/usePermission'
import { useProductSubscription } from '../../hooks/useProductSubscription'
import { useSuccessMessage } from '../../hooks/useSuccessMessage'
import { useWarningMessage } from '../../hooks/useWarningMessage'
import { PermissionType } from '../../models/permission'
import { camelizeUser } from '../../models/user'
import { getUserAndTeammateInfo } from '../../redux/actions/users/actions'
import { currentUserSelector } from '../../redux/selectors/users'
import { useShowSideBar } from '../Layout/Navbar/useShowSideBar'
import { useLayoutContext } from './LayoutContext'

interface AppUserProviderProps {
  children: React.ReactNode
}

export function AppUserProvider({ children }: AppUserProviderProps) {
  const { id } = useParams<{ id: string }>()
  const { refetch: refetchUser } = useUser({
    id: id,
    enabled: false,
  })
  const canViewRates = useCanViewRates()
  // This permission is on user basis, but since it depends just on a team, we can check it on the currently logged in user
  const emailCanBeChanged = usePermission(PermissionType.EMAIL_CAN_BE_CHANGED).enabled
  const canChangeCustomUser = false // Console only permissions
  const canViewReferrals = false // Console only permissions
  const { isPremium, hasStarterFeature, isFlexStarter, hasFactoringFeature, isL2o } = useProductSubscription()
  const canViewVGS = usePermission(PermissionType.CAN_VIEW_VGS).enabled
  const dispatch = useDispatch()
  const successMessage = useSuccessMessage()
  const warningMessage = useWarningMessage()
  const errorMessage = useErrorMessage()
  const loggedInUser = useSelector(currentUserSelector)
  const { setIsSideBarCollapsed } = useLayoutContext()
  const showSidebar = useShowSideBar()

  const showBanner = useCallback(
    (type: 'error' | 'success' | 'warning', data: BannerProps) => {
      if (type === 'error') {
        if (data.message) return errorMessage(data)
        return errorMessage({ message: parseError(data) })
      }
      if (type === 'warning') return warningMessage(data)
      successMessage(data)
    },
    [successMessage, warningMessage, errorMessage]
  )

  const clearUserCache = useCallback(async () => {
    if (id) await refetchUser()
    await dispatch(getUserAndTeammateInfo())
  }, [dispatch, id, refetchUser])

  return (
    <UserProvider
      loggedInUser={camelizeUser(loggedInUser) as LoggedInUser & { isTeamAdmin?: boolean }}
      isSidebarVisible={showSidebar}
      showBanner={showBanner}
      clearUserCache={clearUserCache}
      setIsSideBarCollapsed={setIsSideBarCollapsed}
      permissions={{ canViewRates, emailCanBeChanged, canChangeCustomUser, canViewReferrals, canViewVGS }}
      subscription={{
        isL2o,
        isPremium,
        hasStarterFeature,
        isFlexStarter,
        hasFactoringFeature,
      }}
    >
      {children}
    </UserProvider>
  )
}
