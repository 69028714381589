import Avatar, { AvatarProps } from '@mui/material/Avatar'
import Badge from '@mui/material/Badge'
import Stack from '@mui/material/Stack'
import { css, styled } from 'styled-components'

import CTLogoEclipse from '../../assets/images/CTLogoEclipse.svg'
import { getInitials, getInitialsFromUsername } from '../../helpers/formatters'
import { AddPrefixToObject, WithExclusiveOneOf } from '../../helpers/typeHelper'
import { ListUser } from '../../models/user'
import { black, cyan, darkGray, dividerGray, information, informationL1 } from '../../theme/colors'

type UserAvatarProps = WithExclusiveOneOf<
  StylesProps & {
    onClick?: AvatarProps['onClick']
    className?: string
    backgroundColor?: string
    fontSize?: string
    borderWidth?: string
    borderColor?: string
    displayCTBadge?: boolean
    size?: number
    badgeSize?: number
  },
  { user: ListUser },
  { userName: string }
>

type StylesProps = {
  width?: string
  height?: string
  fontSize?: string
  backgroundColor?: string
  cursor?: string
  borderWidth?: string
  borderColor?: string
  variant?: 'standard' | 'highlighted'
}

const StyledAvatar = styled(Avatar)<AddPrefixToObject<StylesProps, '$'>>`
  width: ${(p) => p.$width || '3rem'};
  height: ${(p) => p.$height || '3rem'};
  border: 3px solid;
  border-color: ${cyan};
  background: ${(p) => p.$backgroundColor || information};
  font-size: ${(p) => p.$fontSize || '14px'};
  cursor: ${(p) => p.$cursor || 'pointer'};
  text-transform: uppercase;
  color: ${darkGray};
  font-weight: 600;
  border-width: ${(p) => p.$borderWidth || '2px'};
  padding-top: 1px;

  ${(p) =>
    p.$variant === 'standard' &&
    css`
      background: ${informationL1};
      border-color: ${dividerGray};
    `};
`

export function UserAvatar({
  onClick,
  user,
  userName,
  displayCTBadge,
  className = '',
  ...stylesProps
}: UserAvatarProps) {
  const initials = (userName && getInitialsFromUsername(userName)) || (user && getInitials(user)) || ''

  return (
    <Stack direction="row" spacing={2}>
      <Badge
        overlap="circular"
        anchorOrigin={{ vertical: 'bottom', horizontal: 'right' }}
        badgeContent={
          displayCTBadge &&
          user?.isStaff && (
            <StyledAvatar
              onClick={onClick}
              src={CTLogoEclipse}
              data-testid="menu-icon"
              $width={`${stylesProps.badgeSize ? stylesProps.badgeSize : 22}px`}
              $height={`${stylesProps.badgeSize ? stylesProps.badgeSize : 22}px`}
              $backgroundColor={black}
              $borderWidth={'0px'}
            />
          )
        }
      >
        <StyledAvatar
          className={className}
          onClick={onClick}
          data-testid="menu-icon"
          $width={stylesProps.size ? `${stylesProps.size}px` : stylesProps.width}
          $height={stylesProps.size ? `${stylesProps.size}px` : stylesProps.height}
          $fontSize={stylesProps.fontSize}
          $backgroundColor={stylesProps.backgroundColor}
          $cursor={stylesProps.cursor}
          $borderWidth={stylesProps.borderWidth}
          $variant={stylesProps.variant}
        >
          {initials}
        </StyledAvatar>
      </Badge>
    </Stack>
  )
}
