import { AppLoad, AppLoadStop, Load, LoadStop, LoadStopType } from '../models/load'
import {
  ctEstimateFormatter,
  ctEstimateFormatterApp,
  ctRangeFormatter,
  ctRangeFormatterApp,
  loadLocationFormatter,
  loadLocationFormatterApp,
} from './formatters'
import { formatEquipments, getTripRate, getTripRatePerMile } from './job'

export function parseLoadDetails(load: Load): Load {
  const origin = load.stops.find((stop) => stop.type === LoadStopType.ORIGIN) as LoadStop
  const hasEstimates = Boolean(load.estimatedRateMin && load.estimatedRateMax)
  const tripRate = getTripRate(load.tripRate || 0)
  const tripRatePerMile = getTripRatePerMile(tripRate, load.tripDistanceMi || 0)
  const loadEquipment = formatEquipments(load.equipment || [])
  const ctRange = load && hasEstimates ? ctRangeFormatter(load) : null
  const ctEstimate = load && hasEstimates ? ctEstimateFormatter(load) : null
  const loadOriginLocation = loadLocationFormatter(origin)
  const destination = load.stops.find((stop) => stop.type === LoadStopType.DESTINATION)
  const loadDestLocation = loadLocationFormatter(destination)

  return {
    ...load,
    details: {
      origin,
      hasEstimates,
      tripRate,
      tripRatePerMile,
      loadEquipment,
      ctRange,
      ctEstimate,
      loadOriginLocation,
      destination,
      loadDestLocation,
    },
  }
}

export function parseAppLoadDetails(load: AppLoad): AppLoad {
  if (load.details) return load

  const origin = load.stops.find((stop) => stop.type === LoadStopType.ORIGIN) as AppLoadStop
  const hasEstimates = Boolean(load.estimated_rate_min && load.estimated_rate_max)
  const tripRate = getTripRate(load.trip_rate || 0)
  const tripRatePerMile = getTripRatePerMile(tripRate, load.trip_distance_mi || 0)
  const loadEquipment = formatEquipments(load.equipment || [])
  const ctRange = load && hasEstimates ? ctRangeFormatterApp(load) : null
  const ctEstimate = load && hasEstimates ? ctEstimateFormatterApp(load) : null
  const loadOriginLocation = loadLocationFormatterApp(origin)
  const destination = load.stops.find((stop) => stop.type === LoadStopType.DESTINATION) as AppLoadStop
  const loadDestLocation = loadLocationFormatterApp(destination)

  return {
    ...load,
    details: {
      origin,
      hasEstimates,
      tripRate,
      tripRatePerMile,
      loadEquipment,
      ctRange,
      ctEstimate,
      loadOriginLocation,
      destination,
      loadDestLocation,
    },
  }
}
