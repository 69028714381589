import CloseIcon from '@mui/icons-material/Close'
import { Button } from 'shared/components/Button/Button'
import { ArrowOut } from 'shared/components/Icons/Icon'
import { HandlerOfOptional } from 'shared/helpers/typeHelper'
import { breakpoints } from 'shared/theme/breakpoints'
import { blueGray } from 'shared/theme/colors'
import { css, styled } from 'styled-components'

import { isConsole } from '../../config/config'
import { FlexBox } from '../Base/Base'

interface DrawerHeaderProps {
  onClose: HandlerOfOptional<boolean>
  openLinkAddress?: string
  children?: React.ReactNode
}

export function DrawerHeader({ onClose, children, openLinkAddress }: DrawerHeaderProps) {
  return (
    <DrawerHeaderWrap className="drawer-header">
      <Button variant="text" onClick={() => onClose(true)} icon={<CloseIcon style={{ color: blueGray }} />} />
      <FlexBox className="drawer-header-title" isFullWidth>
        {children}
      </FlexBox>
      {openLinkAddress && (
        <Button
          variant="text"
          href={openLinkAddress}
          target="_blank"
          icon={<img alt="arrowOutIcon" src={ArrowOut} width={32} />}
        />
      )}
    </DrawerHeaderWrap>
  )
}

const DrawerHeaderWrap = styled.div<{
  $background?: string
}>`
  padding: 55px 40px 40px;
  grid-area: drawer-header;
  position: sticky;
  background: ${({ $background }) => $background ?? 'white'};
  top: 0;
  z-index: 5;

  ${!isConsole() &&
  css`
    padding-top: 20px;
  `}

  @media ${breakpoints.sm} {
    padding: 20px;
  }
`
