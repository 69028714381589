import { Button } from 'shared/components/Button/Button'
import { TaskStatusEnum } from 'shared/components/TaskList/TaskList'
import { OnboardingChecklist, OnboardingSubChecklist } from 'shared/models/onboarding'

interface OnboardingWebinarButtonProps {
  task: OnboardingChecklist | OnboardingSubChecklist
}

function renderText(task: OnboardingChecklist | OnboardingSubChecklist) {
  if (task.status === TaskStatusEnum.NOT_STARTED) {
    return 'Start'
  } else {
    return 'Continue'
  }
}

export function OnboardingWebinarButton(props: OnboardingWebinarButtonProps) {
  return (
    <Button size="medium" variant="secondary" href={props.task.context?.sentix_login_url} target="_blank">
      {renderText(props.task)}
    </Button>
  )
}
