import SearchIcon from '@mui/icons-material/Search'
import React, { forwardRef, useEffect, useState } from 'react'
import styled from 'styled-components'

import { cyanD1 } from '../../theme/colors'
import { TextInput } from '../TextInput/TextInput'
import { InputStyled } from '../TextInput/TextInput.styles'
import { Dropdown, DropdownMonoselectProps, DropdownMultiselectProps, DropdownOption } from './Dropdown'

type DropdownWithSearchOptionMonoselectProps<T> = Omit<DropdownMonoselectProps<T>, 'isMultiselect' | 'value'>
type DropdownWithSearchOptionMultiselectProps<T> = Omit<
  DropdownMultiselectProps<T>,
  'isMultiselect' | 'value'
>

type DropdownWithSearchOptionProps<T> =
  | (DropdownWithSearchOptionMonoselectProps<T> & { isMultiselect?: false; value?: string })
  | (DropdownWithSearchOptionMultiselectProps<T> & { isMultiselect: true; value?: string[] })

export const DropdownWithSearchOption = forwardRef(function DropdownWithSearchOption<T>(
  props: DropdownWithSearchOptionProps<T>,
  ref: any
) {
  const { options, error, warning, dataTestId } = props
  const [currentOptions, setCurrentOptions] = useState(options)
  const [currentValue, setCurrentValue] = useState<string>('')

  useEffect(() => {
    if (currentValue || currentValue === '') {
      setCurrentOptions(
        options.filter((o) => String(o.label)?.toLocaleLowerCase()?.match(currentValue.toLocaleLowerCase()))
      )
    } else {
      setCurrentOptions(options)
    }
  }, [options, currentValue])

  return (
    // @ts-ignore
    <StyledDropdown
      ref={ref}
      {...props}
      options={[
        {
          label: '',
          value: '' as unknown as DropdownOption<T>['value'],
          renderer: () => (
            <StyledTextInput
              key="searchable-dropdown"
              className="searchable-dropdown-input"
              placeholder="Search"
              value={currentValue}
              error={error}
              warning={warning}
              onChange={(e) => setCurrentValue(e.target.value)}
              onClick={(e: React.MouseEvent) => {
                e.preventDefault()
                e.stopPropagation()
              }}
              dataTestid={dataTestId || 'searchable-dropdown'}
              startAdornment={<StyledSearchIcon />}
              noMargin
            />
          ),
        },
        ...currentOptions,
      ]}
    />
  )
})

const StyledDropdown = styled(Dropdown)`
  .dropdown-opener-options {
    padding: 0;
  }
`

const StyledTextInput = styled(TextInput)`
  ${InputStyled} {
    border-bottom: 1px solid ${cyanD1};
  }
`

const StyledSearchIcon = styled(SearchIcon)`
  margin-left: 12px;
`
