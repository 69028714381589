import axios from 'axios'
import { errorD1, mangoYellow, midGray, royalBlue, successD1 } from 'shared/theme/colors'

import { CoreDocument } from './document'

export enum ClearingHouseQueryStatus {
  CREATED = 'CREATED',
  PENDING = 'PENDING',
  PASSED = 'PASSED',
  SUSPENDED = 'SUSPENDED',
  ERROR = 'ERROR',
  CANCELED = 'CANCELED',
}

export const ClearingHouseQueryStatusColors = {
  [ClearingHouseQueryStatus.CREATED]: royalBlue,
  [ClearingHouseQueryStatus.PENDING]: mangoYellow,
  [ClearingHouseQueryStatus.PASSED]: successD1,
  [ClearingHouseQueryStatus.SUSPENDED]: errorD1,
  [ClearingHouseQueryStatus.ERROR]: errorD1,
  [ClearingHouseQueryStatus.CANCELED]: midGray,
}

export const ClearingHouseQueryStatusText = {
  [ClearingHouseQueryStatus.CREATED]: 'Created',
  [ClearingHouseQueryStatus.PENDING]: 'Pending',
  [ClearingHouseQueryStatus.PASSED]: 'Passed',
  [ClearingHouseQueryStatus.SUSPENDED]: 'Suspended',
  [ClearingHouseQueryStatus.ERROR]: 'Error',
  [ClearingHouseQueryStatus.CANCELED]: 'Canceled',
}

export interface ClearingHouseQueryViolation {
  companyName: string
  resultDate: string
  violationDescription: string
}

export interface ClearingHouseQuery {
  id: string
  dateCreated: string
  dateUpdated: string
  dateCompleted: string
  dateLastSynced: string
  dateConsentLinkClicked: string
  status: ClearingHouseQueryStatus
  statusDetail: string
  violations: ClearingHouseQueryViolation[]
  screenshot?: CoreDocument<any>
  metadata: Record<string, any>
}

export function consentClicked(): Promise<void> {
  return axios.post(`/api/v1/clearinghouse-query/consent-link-clicked/`)
}
