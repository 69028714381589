import { STATES } from 'shared/helpers/constants'

// customer success/ sales number for all things onboarding related
export const CLOUDTRUCKS_SUCCESS_MOBILE = '+1 469-250-1214'

// customer support number
export const CLOUDTRUCKS_MARKETING_PAGE_URL = 'https://www.cloudtrucks.com/'

export const ANYWHERE_LABEL = 'Anywhere'
export const REGIONS_AND_STATES = { label: 'Regions and states', value: '...' }
export const CURRENT_LOCATION = 'Current Location'

export const STANDARD_BOOK_TOOLTIP =
  'A load with standard booking has a delayed confirmation, and the availability of load is not guaranteed. '

export const LOAD_BOOKED_ERROR = 'Sorry! This load has already been booked by another driver.'
export const LOAD_NOT_AVAILABLE = 'Sorry! This load is no longer available. Please search for loads again.'

export const RELOAD_ERROR = 'Reload search error'
export const RELOAD_ERROR_DESCRIPTION = "We can't reload search at the moment. Please try again."

export const DRIVER_ONBOARDING_TITLE = 'Booking loads will be available once you complete your onboarding.'
export const DRIVER_ONBOARDING_TEXT =
  'We will let you know when your onboarding is complete. You can also check your progress on your profile.'
export const NO_AVAILABLE_DRIVERS_TITLE = 'There is no available driver for this load.'
export const NO_AVAILABLE_DRIVERS_TEXT = 'Your drivers are still onboarding for CloudTrucks.'

export const DRIVER_CONFLICT_TITLE = 'Scheduling conflict'
export const DRIVER_CONFLICT_TEXT_CURRENT_USER = 'This load conflicts with your schedule:'
export const DRIVER_CONFLICT_TEXT_OTHER_USER = "This load conflicts with your driver's schedule:"
export const DRIVER_CLOSING_TEXT_NON_BLOCKING_CURRENT_USER = 'Check your schedule before you continue.'
export const DRIVER_CLOSING_TEXT_NON_BLOCKING_OTHER_USER = "Check your driver's schedule before you continue."
export const DRIVER_CLOSING_TEXT_BLOCKING_MULTIPLE_DRIVERS =
  'To book this load, try assigning a different driver.'
export const DRIVER_CLOSING_TEXT_BLOCKING_SINGLE_DRIVER =
  'To book this load, contact support@cloudtrucks.com.'
export const DRIVER_CONFLICT_GENERIC_BLOCKING_ERROR_TEXT =
  "This load conflicts with your schedule and can't be booked right now. For help, please contact support@cloudtrucks.com."
export const DRIVER_CONFLICT_GENERIC_NON_BLOCKING_ERROR_TEXT =
  "This load may conflict with your fleet's schedule. Check your schedule to make sure you can move this load before you continue."

export const LOAD_UNCONFIRMED = 'The schedule of this load has not been confirmed.'
export const LOAD_UNCONFIRMED_TEXT =
  'When the broker confirms the load’s info, you may have to adjust your schedule.'

export const MOBILE_PLAY_STORE_LINK =
  'https://play.google.com/store/apps/details?id=com.cloudtrucks.cloudtrucksapp.production'
export const MOBILE_APP_STORE_LINK = 'https://apps.apple.com/us/app/id1523676447'

// Flags
export const FRONTEND_TESTING_FLAG = 'frontend_testing'

export const EDIT_TEAM_BILLING_ADDRESS = 'Edit the permanent address for your business/fleet.'
export const TEAM_BILLING_ADDRESS_ERROR = 'Please enter the permanent address.'
export const DRIVER_MAILING_ADDRESS_ERROR = 'Please enter the mailing address.'
export const POST_BOX_LINE1_ERROR = 'P.O. Box is not accepted in line1.'
export const POST_BOX_LINE2_ERROR = 'P.O. Box is not accepted in line2'
export const POST_BOX_ADDRESS_ERROR = 'P.O. Box is not accepted. Please re-enter your address.'
export const ADDRESS_STREET2_PLACEHOLDER = 'Street 2 (P.O. Box not accepted)'

export const BILL_LADING_ERROR_TITLE = 'We could not confirm the delivery.'
export const BILL_LADING_ERROR_TEXT =
  'This may be because of the bill of lading (proof of delivery) document. Reupload a correct and legible document.'

export const REFERRAL_CODE = 'referral_code'
export const REFERRAL_SOURCE = 'referral_source'

export type STATE_TYPE = { name: string; abbreviation: string }
export type REGION_NAME = 'NORTHEAST' | 'SOUTH' | 'MIDWEST' | 'WEST'

export const STATES_BY_REGIONS: { [key in REGION_NAME]: STATE_TYPE[] } = {
  NORTHEAST: [
    { name: 'Connecticut', abbreviation: 'CT' },
    { name: 'Delaware', abbreviation: 'DE' },
    { name: 'Maine', abbreviation: 'ME' },
    { name: 'Maryland', abbreviation: 'MD' },
    { name: 'Massachusetts', abbreviation: 'MA' },
    { name: 'New Hampshire', abbreviation: 'NH' },
    { name: 'New Jersey', abbreviation: 'NJ' },
    { name: 'New York', abbreviation: 'NY' },
    { name: 'Pennsylvania', abbreviation: 'PA' },
    { name: 'Rhode Island', abbreviation: 'RI' },
    { name: 'Vermont', abbreviation: 'VT' },
  ],
  SOUTH: [
    { name: 'Alabama', abbreviation: 'AL' },
    { name: 'Arkansas', abbreviation: 'AR' },
    { name: 'Florida', abbreviation: 'FL' },
    { name: 'Georgia', abbreviation: 'GA' },
    { name: 'Kentucky', abbreviation: 'KY' },
    { name: 'Louisiana', abbreviation: 'LA' },
    { name: 'Mississippi', abbreviation: 'MS' },
    { name: 'North Carolina', abbreviation: 'NC' },
    { name: 'Oklahoma', abbreviation: 'OK' },
    { name: 'South Carolina', abbreviation: 'SC' },
    { name: 'Tennessee', abbreviation: 'TN' },
    { name: 'Texas', abbreviation: 'TX' },
    { name: 'Virginia', abbreviation: 'VA' },
    { name: 'West Virginia', abbreviation: 'WV' },
  ],
  MIDWEST: [
    { name: 'Illinois', abbreviation: 'IL' },
    { name: 'Indiana', abbreviation: 'IN' },
    { name: 'Iowa', abbreviation: 'IA' },
    { name: 'Kansas', abbreviation: 'KS' },
    { name: 'Michigan', abbreviation: 'MI' },
    { name: 'Minnesota', abbreviation: 'MN' },
    { name: 'Missouri', abbreviation: 'MO' },
    { name: 'Nebraska', abbreviation: 'NE' },
    { name: 'North Dakota', abbreviation: 'ND' },
    { name: 'Ohio', abbreviation: 'OH' },
    { name: 'South Dakota', abbreviation: 'SD' },
    { name: 'Wisconsin', abbreviation: 'WI' },
  ],
  WEST: [
    { name: 'Arizona', abbreviation: 'AZ' },
    { name: 'California', abbreviation: 'CA' },
    { name: 'Colorado', abbreviation: 'CO' },
    { name: 'Idaho', abbreviation: 'ID' },
    { name: 'Montana', abbreviation: 'MT' },
    { name: 'Nevada', abbreviation: 'NV' },
    { name: 'New Mexico', abbreviation: 'NM' },
    { name: 'Utah', abbreviation: 'UT' },
    { name: 'Wyoming', abbreviation: 'WY' },
    { name: 'Oregon', abbreviation: 'OR' },
    { name: 'Washington', abbreviation: 'WA' },
  ],
}

export const ONBOARDING_V2_REDIRECTED_KEY = 'ONBOARDING_V2_REDIRECTED'
export const REGISTER_INTENDED_PRODUCT_KEY = 'INTENDED_PRODUCT'
export const NAVIGATION_HIDE_LINKS_PATHS = ['/onboarding/add-driver/', '/onboarding/add-driver']
export { STATES }
