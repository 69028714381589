import CloseIcon from '@mui/icons-material/Close'
import { IconButton } from '@mui/material'
import { Handler } from 'shared/helpers/typeHelper'
import { styled } from 'styled-components'

import { lightGray1 } from '../../theme/colors'

interface CloseButtonProps {
  size?: string
  className?: string
  onClose: Handler
}

export function CloseButton({ size, onClose, className }: CloseButtonProps) {
  return (
    <StyledIconButton aria-label="close" onClick={onClose} className={className}>
      <StyledCloseIcon $size={size} />
    </StyledIconButton>
  )
}

const StyledIconButton = styled(IconButton)`
  color: ${lightGray1};
  padding: 0;
`

const StyledCloseIcon = styled(CloseIcon)<{ $size?: CloseButtonProps['size'] }>`
  width: ${(p) => p.$size || '34px'};
  height: ${(p) => p.$size || '34px'};
`
