import MenuIcon from '@mui/icons-material/Menu'
import MenuOpenIcon from '@mui/icons-material/MenuOpen'
import Tooltip, { tooltipClasses, TooltipProps } from '@mui/material/Tooltip'
import { snakeCase } from 'lodash'
import { Fragment, SyntheticEvent } from 'react'
import { Link, useHistory, useLocation } from 'react-router-dom'
import { Divider, FlexBox, Grid } from 'shared/components/Base/Base'
import { Text } from 'shared/components/Text/Text'
import { useBreakpoint } from 'shared/hooks/useBreakpoint'
import { breakpoints } from 'shared/theme/breakpoints'
import { blueGray, darkGray, midGray, primaryBlack, sidebarGray, white } from 'shared/theme/colors'
import { label3 } from 'shared/theme/typography'
import styled, { css } from 'styled-components'

import { useAnalyticsEvent } from '../../../hooks/useAnalyticsEvent'
import { useLayoutContext } from '../../Context/LayoutContext'
import { SideBarFooter } from './SideBarFooter'
import { SideBarHeader } from './SideBarHeader'
import { useShowSideBar } from './useShowSideBar'
import { SideBarMenuItem, useSideBarMenuItems } from './useSideBarMenuItems'
import { useSidebarWidth } from './useSideBarWidth'

export const SIDEBAR_EXPANDED_WIDTH = 230
export const SIDEBAR_COLLAPSED_WIDTH = 64
export const SIDEBAR_PADDING = 16

export function SideBar() {
  const { isSideBarCollapsed, setIsSideBarCollapsed, fixNavToTop } = useLayoutContext()
  const location = useLocation()
  const currentPage = location.pathname
  const sidebarMenuItems = useSideBarMenuItems()
  const sidebarWidth = useSidebarWidth()
  const showSidebar = useShowSideBar()
  const isXsMobile = useBreakpoint('xs')
  const trackEvent = useAnalyticsEvent()
  const history = useHistory()

  if (!showSidebar) return null

  function matchesCurrentPage(page: string, pages?: string[]) {
    return Boolean(currentPage.includes(page) || pages?.some((p) => currentPage.includes(p)))
  }

  function handleNavItemClick(e: SyntheticEvent<HTMLAnchorElement>, item: SideBarMenuItem) {
    trackEvent('nav_sidebar_click', { page_name: snakeCase(item.label) })
    if (isXsMobile) {
      e.preventDefault()
      setIsSideBarCollapsed(true)
      // Wait for sidebar to be collapsed for nice animation
      setTimeout(() => history.push(item.url), 300)
    }
  }

  function handleCollapseClick() {
    trackEvent('nav_sidebar_collapse_click', { is_collapsing: !isSideBarCollapsed })
    setIsSideBarCollapsed(!isSideBarCollapsed)
  }

  function handleMobileCollapseClick() {
    trackEvent('nav_sidebar_mobile_collapse_click', { is_collapsing: !isSideBarCollapsed })
    setIsSideBarCollapsed(!isSideBarCollapsed)
  }

  return (
    <>
      <SideBarWrap $isSideBarCollapsed={isSideBarCollapsed}>
        <CollapseIcon
          as={isSideBarCollapsed ? MenuIcon : MenuOpenIcon}
          $isCollapsed={isSideBarCollapsed}
          onClick={handleCollapseClick}
        />
        <KeepWidth>
          <SideBarHeader />
        </KeepWidth>
        <Divider mt={3} mb={1} />
        <SideNav $isSideBarCollapsed={isSideBarCollapsed}>
          {sidebarMenuItems.map((section) => {
            return (
              <Fragment key={section[0].label}>
                {section.map((item) => (
                  <NavItemLink
                    data-testid={snakeCase(item.label)}
                    key={item.label}
                    to={item.url}
                    onClick={(e) => handleNavItemClick(e, item)}
                  >
                    <KeepWidth>
                      <Grid columns="32px auto" alignItems="center" gap="5px">
                        <MenuTooltip
                          title={isSideBarCollapsed ? <TooltipContent>{item.label}</TooltipContent> : null}
                          placement="right"
                        >
                          <FlexBox styles="width: 24px;" ml={0.5}>
                            <NavItemIcon
                              as={item.icon}
                              $isActive={matchesCurrentPage(item.url, item.activeUrls)}
                            />
                          </FlexBox>
                        </MenuTooltip>
                        <NavItemLinkText
                          variant="header3"
                          $isActive={matchesCurrentPage(item.url, item.activeUrls)}
                          $isCollapsed={isSideBarCollapsed}
                        >
                          {item.label}
                        </NavItemLinkText>
                      </Grid>
                    </KeepWidth>
                  </NavItemLink>
                ))}
                <Divider mt={2} mb={1} />
              </Fragment>
            )
          })}
        </SideNav>
        <KeepWidth>
          <SideBarFooter />
        </KeepWidth>
      </SideBarWrap>
      {sidebarWidth === 0 ? (
        <MobileMenuIcon onClick={handleMobileCollapseClick} $fixNavToTop={fixNavToTop} />
      ) : null}
    </>
  )
}

const SideBarWrap = styled.div<{ $isSideBarCollapsed?: boolean }>`
  width: ${SIDEBAR_EXPANDED_WIDTH}px;
  background: ${sidebarGray};
  position: fixed;
  z-index: 9;
  top: 0;
  bottom: 0;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px ${SIDEBAR_PADDING}px 30px;
  transition: width 0.6s;
  box-shadow: 2px 0 2px rgba(64, 75, 83, 0.1);
  display: flex;
  flex-direction: column;

  ${(p) =>
    p.$isSideBarCollapsed &&
    css`
      width: ${SIDEBAR_COLLAPSED_WIDTH}px;
    `}

  @media ${breakpoints.xs} {
    transition: width 0.6s, transform 0.6s;
    transform: translateX(-102%);

    ${(p) =>
      !p.$isSideBarCollapsed &&
      css`
        transform: translateX(0);
      `}
  }
`

const SideNav = styled.div<{ $isSideBarCollapsed?: boolean }>`
  flex-grow: 1;
  display: flex;
  flex-direction: column;

  ${(p) =>
    p.$isSideBarCollapsed &&
    css`
      width: ${SIDEBAR_COLLAPSED_WIDTH - SIDEBAR_PADDING * 2}px;
    `}
`

const NavItemIcon = styled.div<{ $isActive: boolean }>`
  color: ${blueGray};
  transition: color 0.3s !important;

  ${(p) =>
    p.$isActive &&
    css`
      color: ${darkGray};
    `}
`

const NavItemLinkText = styled(Text)<{ $isActive: boolean; $isCollapsed: boolean }>`
  font-size: 15px;
  margin-top: 3px;
  color: ${midGray};
  font-weight: 400;
  transition: opacity 0.3s, color 0.3s, font-weight 0.3s;

  ${(p) =>
    p.$isActive &&
    css`
      color: ${primaryBlack};
      font-weight: 600;
    `}

  ${(p) =>
    p.$isCollapsed &&
    css`
      opacity: 0;
    `}
`

const NavItemLink = styled(Link)`
  display: block;
  padding: 4px 0;
  margin-top: 8px;
  text-decoration: none;
  color: ${midGray};
  border-radius: 3px;
  transition: background 0.3s;

  &:hover {
    background: ${white};
  }
`

const CollapseIcon = styled.div<{ $isCollapsed: boolean }>`
  color: ${blueGray};
  margin: 3px 0 16px auto;
  cursor: pointer;
  transition: color 0.3s;

  &:hover {
    color: ${darkGray};
  }

  ${(p) =>
    p.$isCollapsed &&
    css`
      margin-right: 3px;
    `}
`

const MobileMenuIcon = styled(MenuIcon)<{ $fixNavToTop?: boolean }>`
  position: absolute;
  z-index: 8;
  color: ${blueGray};
  cursor: pointer;
  margin: 22px 0 0 16px;

  ${(p) =>
    p.$fixNavToTop &&
    css`
      position: fixed;
    `}

  &:hover {
    color: ${darkGray};
  }
`

const MenuTooltip = styled(({ className, ...props }: TooltipProps) => (
  <Tooltip {...props} classes={{ popper: className }} />
))(() => ({
  [`& .${tooltipClasses.tooltip}`]: {
    backgroundColor: white,
  },
}))

const TooltipContent = styled.div`
  ${label3};
  color: ${darkGray};
  padding: 1px 5px 0;
`

const KeepWidth = styled.div`
  width: ${SIDEBAR_EXPANDED_WIDTH - SIDEBAR_PADDING * 2}px;
`
