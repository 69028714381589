import styled, { css } from 'styled-components'

import {
  cyan,
  cyanD1,
  darkGray,
  dividerGray,
  errorD1,
  errorL1,
  information,
  informationL1,
  lightGray1,
  lightGray2,
  midGray,
  primaryBlack,
  warningD1,
  white,
} from '../../theme/colors'
import { truncatedText } from '../../theme/mixins'
import { header3, label2, paragraph3 } from '../../theme/typography'

export const DropdownOpenerContainer = styled.div<{
  $noMargin?: boolean
  $isFullWidth?: boolean
  $minWidth?: string
  $width?: string
}>`
  position: relative;
  display: flex;
  flex-direction: column;
`

export const DropdownContainer = styled.div<{
  $noMargin?: boolean
  $isFullWidth?: boolean
  $minWidth?: string
  $maxWidth?: string
}>`
  position: relative;
  display: flex;
  flex-direction: column;
  max-width: 424px;
  min-width: 101px;
  margin-bottom: 24px;
  ${({ $noMargin }) => $noMargin && `margin-bottom: 0;`}
  ${({ $isFullWidth }) => $isFullWidth && `width: 100%;`}
  ${({ $maxWidth }) => $maxWidth && `max-width: ${$maxWidth};`}
`

export const Select = styled.div<{
  $isOpen: boolean
  $hasError?: boolean
  $backgroundColor?: string
  $isDisabled?: boolean
  $hasWarning?: boolean
  $noPadding?: boolean
}>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 12px;
  padding: 0 16px;
  height: 56px;
  border-radius: 3px;
  background: ${white};
  cursor: pointer;

  ${({ $noPadding }) => $noPadding && `padding: 0;`}
  ${({ $backgroundColor }) => $backgroundColor && `background-color: ${$backgroundColor};`}
  ${({ $isDisabled }) => $isDisabled && `pointer-events: none; opacity: .7;`}

  ${({ $hasError }) =>
    $hasError &&
    css`
      border: 1px solid ${errorD1};
      background-color: ${errorL1};
    `}

  ${({ $hasWarning }) =>
    $hasWarning &&
    css`
      border: 1px solid ${warningD1};
    `}
`

export const Label = styled.div<{
  $isSelected?: boolean
  $hasStaticLabel?: boolean
  $labelColor?: string
}>`
  ${truncatedText};
  ${header3};
  font-size: 16px !important;
  line-height: 1.5;
  position: relative;
  top: 1px;
  color: ${({ $labelColor, $isSelected }) =>
    $labelColor ? $labelColor : $isSelected ? primaryBlack : lightGray2};
  ${({ $hasStaticLabel }) => $hasStaticLabel && `margin-top: 13px;`};
`

export const StaticLabel = styled.div`
  position: absolute;
  transition: all 0.2s;
  touch-action: manipulation;
  ${header3};
  font-size: 16px !important;
  color: ${lightGray2};
  left: 16px;
  top: 2px;
  font-weight: 500;
  transform: scale(0.8);
  transform-origin: top left;
`

export const Options = styled.div<{
  $bottom?: number
  $width?: number | string
  $margin?: string
  $align?: 'left' | 'right'
  $position?: 'bottom' | 'top'
  $maxHeight?: number
  $overflow?: 'hidden' | 'visible'
  $fontSize?: string
}>`
  position: absolute;
  bottom: -1px;
  transform: translateY(100%);
  overflow: hidden;
  display: flex;
  flex-direction: column;
  width: 100%;
  max-height: 180px;
  overflow-y: auto;
  background: ${white};
  z-index: 11;
  border: 1px solid ${cyan};
  border-radius: 3px;
  padding: 10px 0;
  ${({ $maxHeight }) => $maxHeight && `max-height: ${$maxHeight}px;`}
  ${({ $bottom }) => $bottom && `bottom: ${$bottom}px;`}
  ${({ $width }) => $width && (typeof $width === 'string' ? `width: ${$width};` : `width: ${$width}px;`)}
  ${({ $align }) => $align === 'right' && `right: 0;`}
  ${({ $overflow }) => $overflow && `overflow: ${$overflow};`}
  ${({ $margin }) => $margin && `margin: ${$margin};`}
  ${({ $fontSize }) => $fontSize && `${Option} { font-size: ${$fontSize} }`}
  ${({ $position }) => $position === 'top' && `top: -1px; bottom: auto; transform: translateY(-100%);`}
`

export const OptionsBottomAction = styled.div<{
  $maxHeight?: number
  $width?: number | string
  $align?: 'left' | 'right'
}>`
  position: absolute;
  overflow: hidden;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 50px;
  background: ${white};
  z-index: 13;
  padding: 10px 0;
  border: 1px solid ${cyan};
  border-top-width: 0;

  ${({ $maxHeight }) => $maxHeight && `top: ${$maxHeight + 55}px;`}
  ${({ $align }) => $align === 'right' && `right: 0;`}
  ${({ $width }) => $width && (typeof $width === 'string' ? `width: ${$width};` : `width: ${$width}px;`)}
`

export const EmptyOption = styled.label`
  ${label2};
  padding: 8px 16px;
  color: ${midGray};
`

export const Option = styled(EmptyOption)<{ $isSelected?: boolean }>`
  display: flex;
  align-items: center;
  justify-content: space-between;
  text-align: left;
  color: ${midGray};

  ${(p) =>
    p.$isSelected &&
    css`
      color: ${cyanD1};
    `}

  .MuiAvatar-circular {
    background-color: ${informationL1};
    border-color: ${dividerGray};

    ${(p) =>
      p.$isSelected &&
      css`
        background-color: ${information};
        border-color: ${cyan};
      `}
  }

  &:hover {
    background: ${informationL1};
    cursor: pointer;
    color: ${primaryBlack};
  }
`

export const Arrow = styled.div<{ $isOpen: boolean; $color?: string }>`
  width: 0;
  height: 0;
  border-left: 5px solid transparent;
  border-right: 5px solid transparent;
  ${({ $isOpen, $color }) =>
    $isOpen
      ? `border-bottom: 5px solid ${$color || lightGray2};`
      : `border-top: 5px solid ${$color || lightGray2};`}
`

export const ErrorMessage = styled.div`
  ${paragraph3};
  position: absolute;
  top: 60px;
  left: 0;
  color: ${errorD1};

  &::first-letter {
    text-transform: capitalize;
  }
`

export const DropdownFilterWrap = styled.div<{ $align?: 'left' | 'right' }>`
  height: 40px;
  display: flex;
  justify-content: flex-end;
  margin-top: 0;

  ${(p) =>
    p.$align === 'left' &&
    css`
      justify-content: flex-start;
    `}
`

export const DropdownFilterOpener = styled.div`
  line-height: 1;
  display: flex;
  justify-content: flex-end;
  cursor: pointer;
  color: ${lightGray1};
  transition: color 0.3s;

  svg {
    color: ${lightGray1};
    transition: color 0.3s;
    position: relative;
    top: -2px;
  }

  &:hover {
    color: ${darkGray};

    svg {
      color: ${darkGray};
    }
  }
`

export const DropdownFilterValue = styled.div<{ $background?: string }>`
  padding: 11px 15px 8px;
  background: white;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 10px;
  box-shadow: 0 8px 8px rgba(0, 44, 177, 0.01);
  border-radius: 3px;

  ${(p) =>
    p.$background &&
    css`
      background: ${p.$background};
    `}

  svg {
    color: ${lightGray2};
    transition: color 0.3s;
    width: 20px;
    height: 20px;
    position: relative;
    top: -1px;
  }

  svg:hover {
    color: ${primaryBlack};
  }
`
