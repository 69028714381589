import { useEffect } from 'react'
import { FieldErrors, FieldValues } from 'react-hook-form'

import { smoothScrollDialogIntoView, smoothScrollIntoView } from '../helpers/utils'

export function scrollToErrors(errors: Record<any, any>) {
  const fieldsWithError = Object.keys(errors)
  const hasErrors = fieldsWithError.length > 0

  if (hasErrors) {
    smoothScrollIntoView(fieldsWithError[0])
  }
}

export function useScrollToErrors<T extends FieldValues>(errors: FieldErrors<T>) {
  const errorKeys = Object.keys(errors).join(',')
  useEffect(() => {
    scrollToErrors(errors)
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [errorKeys])
}

export function scrollToErrorsInDialog(errors: Record<any, any>, parentSelector?: string) {
  const fieldsWithError = Object.keys(errors)
  const hasErrors = fieldsWithError.length > 0
  if (hasErrors) {
    smoothScrollDialogIntoView(fieldsWithError[0], parentSelector)
  }
}
