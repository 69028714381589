import React from 'react'
import { useHistory } from 'react-router-dom'
import { Card, Wrap } from 'shared/components/Base/Base'
import { Box } from 'shared/components/Box/Box'
import { Button } from 'shared/components/Button/Button'
import { Text } from 'shared/components/Text/Text'
import { sizes } from 'shared/theme'
import { midGray } from 'shared/theme/colors'

import { InfoIconLarge } from '../../../components/Icons/Icon'

export const DeadEndDeactivated = () => {
  const history = useHistory()

  return (
    <Wrap maxWidth="632px" align="center">
      <Box mt={10} />
      <Card hasSubtleShadow align="center">
        <img src={InfoIconLarge} width={65} height={65} alt="" />
        <Box mt={2} />
        <Text variant="header2" align="center">
          Your account is not currently active
        </Text>
        <Text variant="paragraph1" align="center" mt={2} fontColor={midGray}>
          To reactivate your account, please contact us at{' '}
          <a href="tel:+14692501214" style={{ textDecoration: 'none' }}>
            +1 (469) 250-1214
          </a>
          .
        </Text>
        <Box mt={4} />
        <Button size={sizes.extraLarge} variant="primary" onClick={() => history.goBack()} margin="0 auto">
          Go back
        </Button>
        <Box mt={2} />
      </Card>
      <Box mt={8} />
    </Wrap>
  )
}
