import Grid from '@mui/material/Grid'
import { Box } from 'shared/components/Box/Box'
import { Button } from 'shared/components/Button/Button'
import { Text } from 'shared/components/Text/Text'
import { Handler } from 'shared/helpers/typeHelper'
import { red } from 'shared/theme/colors'

import { CLOUDTRUCKS_SUCCESS_MOBILE } from 'helpers/constants'

interface MaxAttemptsProps {
  redirectToSignIn: Handler
}

export function MaxAttempts({ redirectToSignIn }: MaxAttemptsProps) {
  return (
    <Grid container justifyContent="center" spacing={2}>
      <Grid item md={8} xs={12}>
        <Text variant="header1" align="center" color={red}>
          Maximum attempts exceeded
        </Text>
      </Grid>
      <Grid item lg={9} xs={11}>
        <Text variant="paragraph1" align="center">
          Please edit your contact information or call CloudTrucks Customer service at{' '}
          {CLOUDTRUCKS_SUCCESS_MOBILE}
        </Text>
      </Grid>
      <Grid item lg={6} xs={12}>
        <Box mt={3} />
        <Button onClick={redirectToSignIn} margin="0 auto">
          Return to home page
        </Button>
      </Grid>
    </Grid>
  )
}
