import ThumbDownIcon from '@mui/icons-material/ThumbDown'
import ThumbDownAltOutlinedIcon from '@mui/icons-material/ThumbDownAltOutlined'
import ThumbUpIcon from '@mui/icons-material/ThumbUp'
import ThumbUpAltOutlinedIcon from '@mui/icons-material/ThumbUpAltOutlined'
import { useState } from 'react'
import { FlexBox } from 'shared/components/Base/FlexBox'
import { CopyToClipBoard } from 'shared/components/CopyToClipBoard/CopyToClipBoard'
import { Handler } from 'shared/helpers/typeHelper'
import { blueGray, midGray } from 'shared/theme/colors'
import styled, { css } from 'styled-components'

import { CopyAllIIcon } from '../../components/Icons/CopyAllIIcon'
import { useErrorMessage } from '../../hooks/useErrorMessage'
import {
  ConsultantConversationMessage,
  ConsultantConversationMessageFeedbackType,
  ConsultantConversationMessageFeedbackTypeDetail,
  submitConsultantMessageFeedback,
} from '../../models/consultant'
import {
  ConsultantMessageFeedbackForm,
  ConsultantMessageFeedbackFormValues,
} from './ConsultantMessageFeedbackForm'

interface ConsultantMessageToolbarProps {
  consultant: string
  message: ConsultantConversationMessage
  onToolbarOpen: Handler
}

export function ConsultantMessageToolbar({
  consultant,
  message,
  onToolbarOpen,
}: ConsultantMessageToolbarProps) {
  const [isPositiveFeedback, setIsPositiveFeedback] = useState(
    message.feedback?.type === ConsultantConversationMessageFeedbackType.THUMBS_UP
  )
  const [isNegativeFeedback, setIsNegativeFeedback] = useState(
    message.feedback?.type === ConsultantConversationMessageFeedbackType.THUMBS_DOWN
  )
  const [showFeedbackForm, setShowFeedbackForm] = useState(false)
  const showErrorMessage = useErrorMessage()

  function handleSubmitPositiveFeedback() {
    setIsPositiveFeedback(true)
    submitConsultantMessageFeedback(consultant, {
      message_id: message.id,
      type: ConsultantConversationMessageFeedbackType.THUMBS_UP,
    }).catch(() => setIsPositiveFeedback(false))
  }

  function handleNegativeFeedback() {
    onToolbarOpen()
    setShowFeedbackForm(true)
  }

  async function handleSubmitNegativeFeedback(feedback: ConsultantMessageFeedbackFormValues) {
    function getTypeDetail() {
      const typeDetails: ConsultantConversationMessageFeedbackTypeDetail[] = []
      if (feedback.inaccurate) typeDetails.push(ConsultantConversationMessageFeedbackTypeDetail.INACCURATE)
      if (feedback.notHelpful) typeDetails.push(ConsultantConversationMessageFeedbackTypeDetail.NOT_HELPFUL)
      if (feedback.offensive) typeDetails.push(ConsultantConversationMessageFeedbackTypeDetail.OFFENSIVE)
      if (feedback.other) typeDetails.push(ConsultantConversationMessageFeedbackTypeDetail.OTHER)
      return typeDetails
    }

    try {
      await submitConsultantMessageFeedback(consultant, {
        message_id: message.id,
        type: ConsultantConversationMessageFeedbackType.THUMBS_DOWN,
        type_detail: getTypeDetail(),
        feedback_content: feedback.feedbackContent,
      })
      setIsNegativeFeedback(true)
      setShowFeedbackForm(false)
    } catch (err) {
      showErrorMessage(err)
    }
  }

  if (showFeedbackForm) {
    return (
      <ConsultantMessageFeedbackForm
        onCancel={() => setShowFeedbackForm(false)}
        onSubmit={handleSubmitNegativeFeedback}
      />
    )
  }

  return (
    <FlexBox justifyContent="flex-end" alignItems="center" gap="15px" styles="height: 25px;">
      {isPositiveFeedback ? (
        <StyledThumbUpIcon />
      ) : isNegativeFeedback ? null : (
        <StyledThumbUpAltOutlinedIcon onClick={handleSubmitPositiveFeedback} />
      )}
      {isNegativeFeedback ? (
        <StyledThumbDownIcon />
      ) : isPositiveFeedback ? null : (
        <StyledThumbDownAltOutlinedIcon onClick={handleNegativeFeedback} />
      )}

      <CopyToClipBoard text={message.text}>
        <CopyToClipBoardWrap>
          <StyledCopyAllIIcon />
        </CopyToClipBoardWrap>
      </CopyToClipBoard>
    </FlexBox>
  )
}

const CopyToClipBoardWrap = styled.div`
  line-height: 1;
  margin-top: 4px;
`

const StyledCopyAllIIcon = styled(CopyAllIIcon)`
  cursor: pointer;

  &:hover path {
    fill: ${midGray};
  }
`

const baseThumbStyles = css`
  font-size: 18px;
  color: ${blueGray};
`

const baseActiveThumbStyles = css`
  ${baseThumbStyles};
  cursor: pointer;

  &:hover {
    color: ${midGray};
  }
`

const StyledThumbUpIcon = styled(ThumbUpIcon)`
  ${baseThumbStyles}
`

const StyledThumbDownIcon = styled(ThumbDownIcon)`
  ${baseThumbStyles}
`

const StyledThumbDownAltOutlinedIcon = styled(ThumbDownAltOutlinedIcon)`
  ${baseActiveThumbStyles}
`

const StyledThumbUpAltOutlinedIcon = styled(ThumbUpAltOutlinedIcon)`
  ${baseActiveThumbStyles}
`
