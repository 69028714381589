import React from 'react'
import styled, { css } from 'styled-components'

import { muiTheme } from '../../theme'
import { warning } from '../../theme/colors'

interface SimpleDialogWarningProps {
  icon?: React.ReactNode
  children: React.ReactNode
}

const StyledWarning = styled.div<{
  $hasIcon?: boolean
}>`
  position: relative;
  padding-top: 20px;
  padding-bottom: 20px;
  background-color: ${warning};
  margin: 0 -${muiTheme.spacing(6)};
  padding-left: ${muiTheme.spacing(6)};
  padding-right: ${muiTheme.spacing(6)};

  @media only screen and (max-width: 600px) {
    margin: 0 -${muiTheme.spacing(4)};
    padding-left: ${muiTheme.spacing(4)};
    padding-right: ${muiTheme.spacing(4)};

    ${(p) =>
      p.$hasIcon &&
      css`
        padding-left: ${muiTheme.spacing(6)};
      `}
  }
`

const IconWrap = styled.div`
  position: absolute;
  left: 8px;
  top: 20px;
`

export function SimpleDialogWarning({ icon, children }: SimpleDialogWarningProps) {
  return (
    <StyledWarning $hasIcon={Boolean(icon)}>
      <IconWrap>{icon}</IconWrap>
      {children}
    </StyledWarning>
  )
}
