/* eslint-disable @typescript-eslint/naming-convention */
// @todo: ctonct Convert to camel case

import axios from 'axios'
import { DateTime } from 'luxon'
import { getVGSDomain } from 'shared/config/config'
import { TestContext } from 'yup'

import { detectEnvironment, Environment } from 'config/config'
import SuccessIconFilled from '../assets/images/SuccessIconFilled.svg'
import { getFormattedDateRange } from '../helpers/formatters'
import { toCamelCase } from '../helpers/utils'

export enum FolderName {
  ROOT = 'ROOT',
  DRIVER_INFO = 'DRIVER_INFO',
  TRUCK = 'TRUCK',
  TRAILER = 'TRAILER',
  TEAM = 'TEAM',
  PERMITS_AND_CERTIFICATES = 'PERMITS_AND_CERTIFICATES',
  ALCOHOL_PERMITS = 'ALCOHOL_PERMITS',
  CLOUDTRUCKS_INFORMATION = 'CLOUDTRUCKS_INFORMATION',
  CLOUDTRUCKS_POLICIES = 'CLOUDTRUCKS_POLICIES',
  ELD_INSTRUCTIONS = 'ELD_INSTRUCTIONS',
  V1_0_GLOBAL_DOCUMENTS = 'V1_0_GLOBAL_DOCUMENTS',
  V1_0_ALCOHOL_PERMITS = 'V1_0_ALCOHOL_PERMITS',
}

export enum DocumentType {
  BANK_DOCUMENT = 'BANK_DOCUMENT',
  DRIVER_LICENSE = 'DRIVER_LICENSE',
  MEDICAL_CARD = 'MEDICAL_CARD',
  INSURANCE_OCCUPATIONAL_ACCIDENT = 'INSURANCE_OCCUPATIONAL_ACCIDENT',
  TEAM_CT_LEASE_AGREEMENT = 'TEAM_CT_LEASE_AGREEMENT',
  PASSENGER_AUTHORIZATION = 'PASSENGER_AUTHORIZATION',
  TRUCK_LAST_DOT_INSPECTION = 'TRUCK_LAST_DOT_INSPECTION',
  TRAILER_LAST_DOT_INSPECTION = 'TRAILER_LAST_DOT_INSPECTION',
  TRUCK_OWNERSHIP_AGREEMENT = 'TRUCK_OWNERSHIP_AGREEMENT',
  TRAILER_OWNERSHIP_AGREEMENT = 'TRAILER_OWNERSHIP_AGREEMENT',
  TRUCK_INSURANCE_PHYSICAL_DAMAGE = 'TRUCK_INSURANCE_PHYSICAL_DAMAGE',
  TRAILER_INSURANCE_PHYSICAL_DAMAGE = 'TRAILER_INSURANCE_PHYSICAL_DAMAGE',
  TRUCK_VEHICLE_REGISTRATION = 'TRUCK_VEHICLE_REGISTRATION',
  TRAILER_VEHICLE_REGISTRATION = 'TRAILER_VEHICLE_REGISTRATION',
  TRUCK_INSURANCE_NON_TRUCKING_LIABILITY = 'TRUCK_INSURANCE_NON_TRUCKING_LIABILITY',
  TRUCK_IFTA_PERMIT = 'TRUCK_IFTA_PERMIT',
  TRUCK_WEIGHT_MILE_PERMIT_KY = 'TRUCK_WEIGHT_MILE_PERMIT_KY',
  TRUCK_WEIGHT_MILE_PERMIT_NY = 'TRUCK_WEIGHT_MILE_PERMIT_NY',
  TRUCK_WEIGHT_MILE_PERMIT_NM = 'TRUCK_WEIGHT_MILE_PERMIT_NM',
  TRUCK_WEIGHT_MILE_PERMIT_OR = 'TRUCK_WEIGHT_MILE_PERMIT_OR',
  TRUCK_TAX_2290 = 'TRUCK_TAX_2290',
  GLOBAL_DOCUMENT = 'GLOBAL_DOCUMENT',
  TRUCK_LEASING_AGREEMENT = 'TRUCK_LEASING_AGREEMENT',
  TRUCK_TITLE = 'TRUCK_TITLE',
}

export enum DocumentAttachmentType {
  MEDICAL_CARD = 'MEDICAL_CARD',
  OWNERSHIP_AGREEMENT = 'OWNERSHIP_AGREEMENT',
  INSURANCE_PHYSICAL_DAMAGE = 'INSURANCE_PHYSICAL_DAMAGE',
  INSURANCE_NON_TRUCKING_LIABILITY = 'INSURANCE_NON_TRUCKING_LIABILITY',
  IFTA_PERMIT = 'IFTA_PERMIT',
  TAX_2290 = 'TAX_2290',
  LAST_DOT_INSPECTION = 'LAST_DOT_INSPECTION',
  VEHICLE_REGISTRATION = 'VEHICLE_REGISTRATION',
  TEAM_CT_LEASE_AGREEMENT = 'TEAM_CT_LEASE_AGREEMENT',
  INSURANCE_OCCUPATIONAL_ACCIDENT = 'INSURANCE_OCCUPATIONAL_ACCIDENT',
  PASSENGER_AUTHORIZATION = 'PASSENGER_AUTHORIZATION',
  DRIVER_LICENSE_FRONT = 'DRIVER_LICENSE_FRONT',
  DRIVER_LICENSE_BACK = 'DRIVER_LICENSE_BACK',
  PSP_REPORT_DOCUMENT = 'PSP_REPORT_DOCUMENT',
  TEAM_CT_LEASE_AGREEMENT_AMENDMENT = 'TEAM_CT_LEASE_AGREEMENT_AMENDMENT',
  LEASING_AGREEMENT = 'LEASING_AGREEMENT',
  TITLE_FRONT = 'TITLE_FRONT',
  TITLE_BACK = 'TITLE_BACK',
}

export const DocumentAttachmentTypeLabels: Record<DocumentAttachmentType, string> = {
  [DocumentAttachmentType.MEDICAL_CARD]: 'Medical Card',
  [DocumentAttachmentType.OWNERSHIP_AGREEMENT]: 'Ownership Agreement',
  [DocumentAttachmentType.INSURANCE_PHYSICAL_DAMAGE]: 'Insurance Physical Damage',
  [DocumentAttachmentType.INSURANCE_NON_TRUCKING_LIABILITY]: 'Insurance Non-Trucking Liability',
  [DocumentAttachmentType.IFTA_PERMIT]: 'IFTA Permit',
  [DocumentAttachmentType.TAX_2290]: 'Tax 2290',
  [DocumentAttachmentType.LAST_DOT_INSPECTION]: 'Last DOT Inspection',
  [DocumentAttachmentType.VEHICLE_REGISTRATION]: 'Vehicle Registration',
  [DocumentAttachmentType.TEAM_CT_LEASE_AGREEMENT]: 'CT Lease Agreement',
  [DocumentAttachmentType.INSURANCE_OCCUPATIONAL_ACCIDENT]: 'Driver COI: Occupational Accident',
  [DocumentAttachmentType.PASSENGER_AUTHORIZATION]: 'Driver: Passenger Authorization',
  [DocumentAttachmentType.DRIVER_LICENSE_FRONT]: 'License Front',
  [DocumentAttachmentType.DRIVER_LICENSE_BACK]: 'License Back',
  [DocumentAttachmentType.PSP_REPORT_DOCUMENT]: 'PSP Report',
  [DocumentAttachmentType.TEAM_CT_LEASE_AGREEMENT_AMENDMENT]: 'CT Lease Amendment',
  [DocumentAttachmentType.LEASING_AGREEMENT]: 'Leasing Agreement',
  [DocumentAttachmentType.TITLE_FRONT]: 'Title Front',
  [DocumentAttachmentType.TITLE_BACK]: 'Title Back',
}

export enum DocumentMetadata {
  DRIVER_LICENSE_ISSUE_DATE = 'DRIVER_LICENSE_ISSUE_DATE',
  DRIVER_LICENSE_NUMBER = 'DRIVER_LICENSE_NUMBER',
  DRIVER_LICENSE_STATE = 'DRIVER_LICENSE_STATE',
  DATE_LAST_DOT_INSPECTION = 'DATE_LAST_DOT_INSPECTION',
}

export const PERMITBOOK_DOCUMENTS = [
  DocumentType.DRIVER_LICENSE,
  DocumentType.MEDICAL_CARD,
  DocumentType.INSURANCE_OCCUPATIONAL_ACCIDENT,
  DocumentType.TEAM_CT_LEASE_AGREEMENT,
  DocumentType.PASSENGER_AUTHORIZATION,
  DocumentType.TRUCK_LAST_DOT_INSPECTION,
  DocumentType.TRAILER_LAST_DOT_INSPECTION,
  DocumentType.TRUCK_INSURANCE_PHYSICAL_DAMAGE,
  DocumentType.TRAILER_INSURANCE_PHYSICAL_DAMAGE,
  DocumentType.TRUCK_VEHICLE_REGISTRATION,
  DocumentType.TRAILER_VEHICLE_REGISTRATION,
  DocumentType.TRUCK_INSURANCE_NON_TRUCKING_LIABILITY,
  DocumentType.TRUCK_IFTA_PERMIT,
  DocumentType.TRUCK_TAX_2290,
  DocumentType.TRUCK_TAX_2290,
  DocumentType.TRUCK_LEASING_AGREEMENT,
  DocumentType.TRUCK_TITLE,
]

export const DOCUMENTS_WITH_EFFECTIVE_DATE = [
  DocumentType.INSURANCE_OCCUPATIONAL_ACCIDENT,
  DocumentType.TEAM_CT_LEASE_AGREEMENT,
  DocumentType.PASSENGER_AUTHORIZATION,
  DocumentType.TRUCK_INSURANCE_PHYSICAL_DAMAGE,
  DocumentType.TRAILER_INSURANCE_PHYSICAL_DAMAGE,
  DocumentType.TRUCK_INSURANCE_NON_TRUCKING_LIABILITY,
  DocumentType.TRUCK_TAX_2290,
]

export const EQUIPMENT_FOLDERS = [FolderName.TRUCK, FolderName.TRAILER]

export const DOCUMENTS_WITH_EXPIRATION_DATE_OPTIONAL = [
  DocumentType.TRUCK_OWNERSHIP_AGREEMENT,
  DocumentType.TRUCK_TAX_2290,
  DocumentType.GLOBAL_DOCUMENT,
  DocumentType.TEAM_CT_LEASE_AGREEMENT,
  DocumentType.TRAILER_VEHICLE_REGISTRATION,
  DocumentType.TRUCK_VEHICLE_REGISTRATION,
]

export const ENCRYPTED_DOCUMENTS = [DocumentType.TRUCK_TAX_2290]

export const getDocumentTypeFromSourceAndEquipmentType = (source: string, isTruck: boolean) => {
  const equipmentPrefix = isTruck ? 'TRUCK_' : 'TRAILER_'
  return equipmentPrefix + source
}

export const FOLDER_NAME_TO_CATEGORY: Record<any, string> = {
  DRIVER_INFO: 'Driver Information',
  TRUCK: 'Equipment',
}

export const DOCUMENT_STATUS_CHOICES = [
  { id: 'EXPIRED', name: 'Expired' },
  { id: 'MISSING_DATE', name: 'Missing date' },
  { id: 'NOT_UPLOADED', name: 'Not uploaded' },
  { id: 'EXPIRING', name: 'Expiring soon' },
  { id: 'VALID', name: 'Valid' },
  { id: 'REVIEW_NEEDED', name: 'Review needed' },
]

export const SAFETY_TOOL_DOCUMENT_STATUS_CHOICES = [
  { id: 'EXPIRED', name: 'Expired' },
  { id: 'NOT_UPLOADED', name: 'Not uploaded' },
  { id: 'EXPIRING', name: 'Expiring soon' },
  { id: 'REVIEW_NEEDED', name: 'Review needed' },
]

export const HOS_VIOLATIONS = [
  { id: 'hos_driven_flag', name: '11 + Driving' },
  { id: 'hos_active_flag', name: '14+ Active' },
]

export const getTimeFrameChoices = () => {
  const dateTimeToday = DateTime.now().setZone('America/Chicago')
  // get start of last full week (sunday)
  const startOfLastWeek = dateTimeToday.endOf('week').minus({ weeks: 2 })
  // get start of last full month
  const startOfLastMonth = dateTimeToday.minus({ days: 31 })
  // get end of last full week (sunday)
  const endOfLastWeek = dateTimeToday.startOf('week')
  return [
    {
      id: 'WEEKLY',
      name: getFormattedDateRange(startOfLastWeek.toJSDate(), endOfLastWeek.toJSDate(), 'America/Chicago'),
    },
    {
      id: 'MONTHLY',
      name: getFormattedDateRange(startOfLastMonth.toJSDate(), endOfLastWeek.toJSDate(), 'America/Chicago'),
    },
  ]
}

interface DocumentTypeConfig {
  name: string
  attachmentTypes: DocumentAttachmentType[]
}

export const DOCUMENT_TYPES: Partial<Record<DocumentType, DocumentTypeConfig>> = {
  [DocumentType.DRIVER_LICENSE]: {
    name: 'CDL',
    attachmentTypes: [
      DocumentAttachmentType.DRIVER_LICENSE_FRONT,
      DocumentAttachmentType.DRIVER_LICENSE_BACK,
    ],
  },
  [DocumentType.MEDICAL_CARD]: {
    name: 'Medical Card',
    attachmentTypes: [DocumentAttachmentType.MEDICAL_CARD],
  },
  [DocumentType.INSURANCE_OCCUPATIONAL_ACCIDENT]: {
    name: 'Driver COI: Occupational Accident',
    attachmentTypes: [DocumentAttachmentType.INSURANCE_OCCUPATIONAL_ACCIDENT],
  },
  [DocumentType.TEAM_CT_LEASE_AGREEMENT]: {
    name: 'CT Lease Agreement',
    attachmentTypes: [DocumentAttachmentType.TEAM_CT_LEASE_AGREEMENT],
  },
  [DocumentType.PASSENGER_AUTHORIZATION]: {
    name: 'Driver: Passenger Authorization',
    attachmentTypes: [DocumentAttachmentType.PASSENGER_AUTHORIZATION],
  },
  [DocumentType.TRUCK_VEHICLE_REGISTRATION]: {
    name: 'Truck Registration',
    attachmentTypes: [DocumentAttachmentType.VEHICLE_REGISTRATION],
  },
  [DocumentType.TRUCK_LAST_DOT_INSPECTION]: {
    name: 'Truck Inspection',
    attachmentTypes: [DocumentAttachmentType.LAST_DOT_INSPECTION],
  },
  [DocumentType.TRUCK_INSURANCE_NON_TRUCKING_LIABILITY]: {
    name: 'Truck COI: Non Trucking Liability',
    attachmentTypes: [DocumentAttachmentType.INSURANCE_NON_TRUCKING_LIABILITY],
  },
  [DocumentType.TRUCK_INSURANCE_PHYSICAL_DAMAGE]: {
    name: 'Truck COI: Physical Damage',
    attachmentTypes: [DocumentAttachmentType.INSURANCE_PHYSICAL_DAMAGE],
  },
  [DocumentType.TRUCK_OWNERSHIP_AGREEMENT]: {
    name: 'Truck: Ownership agreement',
    attachmentTypes: [DocumentAttachmentType.OWNERSHIP_AGREEMENT],
  },
  [DocumentType.TRAILER_LAST_DOT_INSPECTION]: {
    name: 'Trailer Inspection',
    attachmentTypes: [DocumentAttachmentType.LAST_DOT_INSPECTION],
  },
  [DocumentType.TRAILER_VEHICLE_REGISTRATION]: {
    name: 'Trailer Registration',
    attachmentTypes: [DocumentAttachmentType.VEHICLE_REGISTRATION],
  },
  [DocumentType.TRAILER_INSURANCE_PHYSICAL_DAMAGE]: {
    name: 'Trailer COI: Physical Damage',
    attachmentTypes: [DocumentAttachmentType.INSURANCE_PHYSICAL_DAMAGE],
  },
  [DocumentType.TRUCK_IFTA_PERMIT]: {
    name: 'IFTA Permit',
    attachmentTypes: [DocumentAttachmentType.IFTA_PERMIT],
  },
  [DocumentType.TRUCK_TAX_2290]: {
    name: '2290 (optional)',
    attachmentTypes: [DocumentAttachmentType.TAX_2290],
  },
  [DocumentType.TRUCK_LEASING_AGREEMENT]: {
    name: 'Truck leasing agreement',
    attachmentTypes: [DocumentAttachmentType.LEASING_AGREEMENT],
  },
  [DocumentType.TRUCK_TITLE]: {
    name: 'Truck title',
    attachmentTypes: [DocumentAttachmentType.TITLE_FRONT, DocumentAttachmentType.TITLE_BACK],
  },
}

export const GLOBAL_DOCUMENT_CATEGORY_CHOICES = [
  { id: FolderName.PERMITS_AND_CERTIFICATES, name: 'Permits & Certificates' },
  { id: FolderName.ALCOHOL_PERMITS, name: 'Permits & Certificates > Alcohol Haul Permits' },
  { id: FolderName.CLOUDTRUCKS_INFORMATION, name: 'CloudTrucks Information' },
  { id: FolderName.CLOUDTRUCKS_POLICIES, name: 'CloudTrucks Policies' },
  { id: FolderName.TRUCK, name: 'Equipment > Truck' },
  { id: FolderName.ELD_INSTRUCTIONS, name: 'ELD Instructions' },
  { id: FolderName.V1_0_GLOBAL_DOCUMENTS, name: 'CT Managed v1.0' },
  { id: FolderName.V1_0_ALCOHOL_PERMITS, name: 'CT Managed v1.0 > Alcohol Permits' },
]

export const DOCUMENT_STATUS_CONFIG: Record<string, any> = {
  EXPIRED: {
    value: 'Expired',
    backgroundColor: '#FCE6DB',
    color: '#CC0033',
  },
  MISSING: {
    value: 'Missing',
    backgroundColor: '#FCE6DB',
    color: '#CC0033',
  },
  MISSING_DATE: {
    value: 'Missing Date',
    backgroundColor: '#FCE6DB',
    color: '#CC0033',
  },
  undefined: {
    value: 'Not Uploaded',
    backgroundColor: '#FCE6DB',
    color: '#CC0033',
  },
  NOT_UPLOADED: {
    value: 'Not Uploaded',
    backgroundColor: '#FCE6DB',
    color: '#CC0033',
  },
  EXPIRING_SOON: {
    value: 'Expiring Soon',
    backgroundColor: '#FFF3C8',
    color: '#E48900',
  },
  EXPIRING: {
    value: 'Expiring Soon',
    backgroundColor: '#FFF3C8',
    color: '#E48900',
  },
  VEHICLE_NOT_ASSIGNED: {
    value: 'Vehicle Not Assigned',
    backgroundColor: 'none',
    color: 'gray',
  },
  VALID: {
    value: 'Valid',
    backgroundColor: '#D1F5E8',
    color: '#00AA6D',
    icon: SuccessIconFilled,
  },
  REVIEW_NEEDED: {
    value: 'Review Needed',
    backgroundColor: '#FCE6DB',
    color: '#CC0033',
  },
  REJECTED: {
    value: 'Rejected',
    backgroundColor: '#FCE6DB',
    color: '#CC0033',
  },
}

export enum DocumentStatus {
  EXPIRED = 'EXPIRED',
  EXPIRING = 'EXPIRING',
  MISSING = 'MISSING',
  NOT_UPLOADED = 'NOT_UPLOADED',
  REJECTED = 'REJECTED',
  REVIEW_NEEDED = 'REVIEW_NEEDED',
  VALID = 'VALID',
}

export interface ChildrenDocumentAttachment {
  attachmentFile: string
  attachmentFileEncrypted: string
  attachmentType: string
  createdBy: string
  id: string
  metadata: any
  mimetype: string
  originalFilename: string
  reviewStatus: DocumentStatus
  status: DocumentStatus
  updatedBy: string
  versionNumber: number
}

export interface ChildrenDocument {
  createdBy: string
  dateCreated: string
  dateEffective: string
  dateExpired: string
  dateUpdated: string
  description: string
  documentAttachmentVersions: ChildrenDocumentAttachment[][]
  documentAttachments: ChildrenDocumentAttachment[]
  documentName: string
  documentType: DocumentType
  folderName: FolderName
  id: string
  metadata: Record<string, any>
  relatedModels: Record<string, string | number>
  status: DocumentStatus
  updatedBy: string
}

export interface ChildrenDocumentMissing {
  documentName: string
  documentType: DocumentType
  folderName: FolderName
}

export interface DocumentFolder {
  childrenDocumentsMissing: ChildrenDocumentMissing[]
  childrenDocumentsPresent: ChildrenDocument[]
  childrenFolders: DocumentFolder[]
  folderName: string
  metadata: any
}

export enum CoreDocumentStatus {
  EXPIRED = 'EXPIRED',
  EXPIRING = 'EXPIRING',
  MISSING = 'MISSING',
  NOT_UPLOADED = 'NOT_UPLOADED',
  REJECTED = 'REJECTED',
  REVIEW_NEEDED = 'REVIEW_NEEDED',
  VALID = 'VALID',
}

export function isCoreDocumentAttachment(
  attachment: File | Attachment<any> | CoreDocumentAttachment<any> | null | undefined
): attachment is CoreDocumentAttachment<any> {
  return attachment ? 'attachmentFile' in attachment : false
}

export interface CoreDocumentAttachment<T extends Record<string, any>> {
  attachmentFile: string
  attachmentType: string
  id: string
  metadata: T
  mimetype: string
  originalFilename: string
  reviewStatus: CoreDocumentStatus
  status: CoreDocumentStatus
}

export interface CoreDocument<T extends Record<string, any>> {
  createdBy: string
  dateCreated: string
  dateEffective: string | null
  dateExpired: string | null
  description: string
  documentAttachmentVersions: Array<CoreDocumentAttachment<T>>
  documentAttachments: Array<CoreDocumentAttachment<T>>
  documentName: string
  documentType: string
  id: string
  isCtManagedDocument: boolean
  metadata: T
  status: CoreDocumentStatus
  isValidStatus: boolean
  firstAttachmentFilename?: string
}

export function isChildrenDocument(
  document?: ChildrenDocument | ChildrenDocumentMissing
): document is ChildrenDocument {
  if (!document) return false
  if ('status' in document) return true
  return false
}

// API

export function getDocuments(record: string, folderName: FolderName): Promise<DocumentFolder> {
  const url = `/api/v1/console/documents/${record}/all/`
  return axios.get(url, { params: { folder_name: folderName } }).then((response) => {
    return toCamelCase(response.data) as DocumentFolder
  })
}

export function decryptDocument(documentId: string, attachmentId: string): Promise<any> {
  const url = `${getVGSDomain()}/api/v1/console/documents/${documentId}/reveal/`
  const payload = { document_attachment_pk: attachmentId }
  const withCredentials = detectEnvironment() === Environment.DEVELOPMENT ? false : true

  return axios.post(url, payload, { withCredentials }).then((response) => {
    return response.data
  })
}

export interface Attachment<T extends Record<string, any>> {
  id: string
  file: string
  attachment_file: string
  mimetype: string
  original_filename: string
  attachment_type: string
  metadata: T
  review_status: CoreDocumentStatus
  version_number: number
  status: CoreDocumentStatus
  created_by: string
  updated_by: string
}

export interface Document<T extends Record<string, any>> {
  document_attachments: Attachment<T>[]
  document_attachment_versions: Attachment<T>[]

  created_by: string
  date_created: string
  date_effective: string | null
  date_expired: string
  date_updated: string
  description: string
  document_name: string
  document_type: string
  folder_name: string
  id: string
  is_ct_managed_document: boolean
  metadata: T
  related_models: Record<string, string>
  status: CoreDocumentStatus
  updated_by: string
}

export function parseCoreDocumentAttachment<T extends Record<string, any>>(
  a: Attachment<T>
): CoreDocumentAttachment<T> {
  return {
    attachmentFile: a.attachment_file,
    attachmentType: a.attachment_type,
    id: a.id,
    metadata: a.metadata,
    mimetype: a.mimetype,
    originalFilename: a.original_filename,
    reviewStatus: a.review_status,
    status: a.status,
  }
}

export function parseCoreDocument<T extends Record<string, any>>(
  d: Document<T> | undefined
): CoreDocument<T> | null {
  if (!d) return null

  return {
    createdBy: d.created_by,
    dateCreated: d.date_created,
    dateEffective: d.date_effective,
    dateExpired: d.date_expired,
    description: d.description,
    documentAttachmentVersions: d.document_attachment_versions.map(parseCoreDocumentAttachment),
    documentAttachments: d.document_attachments.map(parseCoreDocumentAttachment),
    documentName: d.document_name,
    documentType: d.document_type,
    id: d.id,
    isCtManagedDocument: d.is_ct_managed_document,
    metadata: d.metadata,
    status: d.status,
    isValidStatus: d.status === CoreDocumentStatus.VALID,
    firstAttachmentFilename: d.document_attachments[0]?.original_filename,
  }
}

export function validateDocument(
  document: string,
  expiration: string | undefined,
  documentFromServer: string | undefined,
  createError: TestContext['createError']
) {
  if (documentFromServer) return true

  if (!document && expiration) {
    return createError({
      message: 'This field is required.',
    })
  }
  return true
}

export function isAttachment(file: File | Attachment<any>): file is Attachment<any> {
  return 'file' in file || 'attachment_file' in file
}
