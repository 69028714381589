import { Portal } from '@mui/base/Portal'
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined'
import SnackbarMUI from '@mui/material/Snackbar'
import { FlexBox } from 'shared/components/Base/FlexBox'
import { Box } from 'shared/components/Box/Box'
import { Button } from 'shared/components/Button/Button'
import { CloseButton } from 'shared/components/CloseButton/CloseButton'
import { ErrorIcon, SuccessIcon, WarningIcon } from 'shared/components/Icons/Icon'
import { OutlinedIcon } from 'shared/components/Icons/OutlinedIcon'
import { Text } from 'shared/components/Text/Text'
import { Handler } from 'shared/helpers/typeHelper'
import { breakpoints } from 'shared/theme/breakpoints'
import { error, information, success, warning } from 'shared/theme/colors'
import styled, { css } from 'styled-components'

import { snackbarsState } from '../../reducers/snackbarsReducer'

interface SnackbarProps {
  isOpen: boolean
  type: 'success' | 'error' | 'info' | 'warning'
  title?: string
  message: string | string[]
  footer?: string
  action: null | Handler
  actionLabel: string
  actionRenderer: (() => React.ReactNode) | null
  onClose: Handler
  autoHideDuration?: number
}

export interface SnackBarReduxProps {
  useSelector: <T>(selector: (state: { snackbars: snackbarsState }) => T) => T
  onClose: Handler
}

const Alert = styled.div<{ $type: SnackbarProps['type'] }>`
  border-radius: 6px;
  padding: 30px 60px;
  display: grid;
  margin: 0 auto;
  grid-template-columns: 36px 1fr 35px;
  gap: 20px;
  width: 100%;
  max-width: calc(1440px - 48px);

  ${(p) =>
    p.$type === 'success' &&
    css`
      background-color: ${success};
    `}

  ${(p) =>
    p.$type === 'info' &&
    css`
      background-color: ${information};
    `}

  ${(p) =>
    p.$type === 'error' &&
    css`
      background-color: ${error};
    `}

  ${(p) =>
    p.$type === 'warning' &&
    css`
      background-color: ${warning};
    `}

  @media ${breakpoints.sm} {
    padding: 20px;
  }
`

const Content = styled.div`
  display: grid;
  grid-template-columns: 1fr auto;
  gap: 15px;
  align-items: center;

  @media ${breakpoints.sm} {
    grid-template-columns: 1fr;
  }
`

const ContentInner = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;

  /* All children except fist one */
  > * + * {
    margin-top: 3px;
  }
`

const StyledSnackbarMUI = styled(SnackbarMUI)`
  width: 100%;
  padding: 0 24px;
  top: 75px;

  @media ${breakpoints.xs} {
    width: auto;
    padding: 0;
  }
`

const InfoIcon = styled(OutlinedIcon)`
  width: 36px !important;
  height: 36px !important;
  padding: 3px;
  background: white;
`

export function Snackbar({
  isOpen,
  type,
  message,
  footer,
  action,
  actionLabel,
  actionRenderer,
  title,
  onClose,
  autoHideDuration = 20000,
}: SnackbarProps) {
  function renderCta() {
    if (actionRenderer) {
      return <div>{actionRenderer()}</div>
    }

    function handleAction() {
      action?.()
      onClose()
    }

    if (action && actionLabel) {
      return (
        <div>
          <Box mt={1} />
          <Button onClick={handleAction} variant="secondary" size="medium">
            {actionLabel}
          </Button>
        </div>
      )
    }

    return null
  }

  function renderIcon() {
    if (type === 'success') {
      return <img src={SuccessIcon} alt="success_icon" width="36" height="36" />
    }

    if (type === 'error') {
      return <img src={ErrorIcon} alt="error_icon" width="36" height="36" />
    }

    if (type === 'warning') {
      return <img src={WarningIcon} alt="warning_icon" width="36" height="36" />
    }

    return <InfoIcon Icon={InfoOutlinedIcon} />
  }

  return (
    <Portal>
      <StyledSnackbarMUI
        anchorOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }}
        open={isOpen}
        autoHideDuration={autoHideDuration}
        onClose={onClose}
      >
        <Alert $type={type}>
          <div>{renderIcon()}</div>
          <Content>
            <ContentInner>
              {title && (
                <Text variant="header3" mb={1}>
                  {title}
                </Text>
              )}
              {message &&
                (typeof message === 'string' ? (
                  <Text variant="paragraph1">{message}</Text>
                ) : (
                  <ul>
                    {message.map((msg, index) => (
                      <li key={index}>
                        <Text variant="paragraph1">{msg}</Text>
                      </li>
                    ))}
                  </ul>
                ))}
              {footer && <Text variant="paragraph1">{footer}</Text>}
            </ContentInner>
            {renderCta()}
          </Content>
          <FlexBox isColumn justifyContent="center">
            <CloseButton onClose={onClose} />
          </FlexBox>
        </Alert>
      </StyledSnackbarMUI>
    </Portal>
  )
}
