export const KEYBOARD = {
  SPACE_BAR: ' ',
  ARROW_DOWN: 'ArrowDown',
  ARROW_UP: 'ArrowUp',
  ARROW_LEFT: 'ArrowLeft',
  ARROW_RIGHT: 'ArrowRight',
  ENTER: 'Enter',
  ESCAPE: 'Escape',
  BACKSPACE: 'Backspace',
  DELETE: 'Delete',
}

export enum COMPOSITION {
  LOO = 'LOO', // Lease Owner-Operator
  CD = 'CD', // Commercial Driver
  OOA = 'OOA', // Owner-Operator with Authority
}

// Search-related
export const ANYWHERE = 'ANYWHERE'
export const DEFAULT_RADIUS = 50

// Load provider choices
// sync with cloudtrucks/queryengine/constants.py#L102
export const PROVIDER_CHOICES = [
  { id: 'abc', name: 'American Bottling Company' },
  { id: 'arrive', name: 'Arrive Logistics' },
  { id: 'atlas', name: 'Atlas' },
  { id: 'ats', name: 'ATS Brokered Loads' },
  { id: 'bluetriton', name: 'BlueTriton' },
  { id: 'chrobinson', name: 'C.H. Robinson' },
  { id: 'constellation', name: 'Constellation Brands' },
  { id: 'convoy', name: 'Convoy' },
  { id: 'coyote', name: 'Coyote' },
  { id: 'ctexchange', name: 'CloudTrucks Exchange' },
  { id: 'dart', name: 'Dart Advantage Logistics' },
  { id: 'DAT', name: 'DAT' },
  { id: 'drpepper', name: 'Dr. Pepper' },
  { id: 'echo', name: 'Echo Global Logistics' },
  { id: 'edge', name: 'Edge Logistics' },
  { id: 'emerge', name: 'Emerge' },
  { id: 'england', name: 'England Logistics' },
  { id: 'external', name: 'External' },
  { id: 'freightvana', name: 'Freight Vana' },
  { id: 'jbhunt', name: 'J.B. Hunt' },
  { id: 'keeptruckin', name: 'Motive' },
  { id: 'keurig', name: 'Keurig Green Mountain' },
  { id: 'kirsch', name: 'Kirsch' },
  { id: 'knightswift', name: 'Knight-Swift' },
  { id: '123loadboard', name: '123Loadboard' },
  { id: 'loadsmart', name: 'Loadsmart' },
  { id: 'matson', name: 'Matson Logistics' },
  { id: 'mode', name: 'Mode Transportation' },
  { id: 'motts', name: "Mott's" },
  { id: 'newtrul', name: 'Newtrul' },
  { id: 'nolan', name: 'Nolan Transport' },
  { id: 'ocean_spray', name: 'Ocean Spray' },
  { id: 'parade', name: 'Parade' },
  { id: 'project44', name: 'Project44' },
  { id: 'redtrucks', name: 'Red Trucks' },
  { id: 'rlglobal', name: 'RL Global Logistics' },
  { id: 'schneider', name: 'Schneider FreightPower' },
  { id: 'TQL', name: 'TQL' },
  { id: 'transfix', name: 'Transfix' },
  { id: 'truckerpath', name: 'Trucker Path' },
  { id: 'truckertools', name: 'TruckerTools' },
  { id: 'truckstop', name: 'Truckstop.com' },
  { id: 'uberfreight', name: 'UberFreight' },
  { id: 'walmart', name: 'Walmart' },
  { id: 'xpo', name: 'RXO' },
]

export const SOURCE_NAME_CHOICES = [{ id: 'external', name: 'External' }].concat(PROVIDER_CHOICES)

export const SHIPPER_SOURCE_NAME_CHOICES = [
  { id: 'abc', name: 'American Bottling Company' },
  { id: 'atlas', name: 'Atlas' },
  { id: 'bluetriton', name: 'BlueTriton' },
  { id: 'coca_cola', name: 'Coca-Cola' },
  { id: 'constellation', name: 'Constellation Brands' },
  { id: 'drpepper', name: 'Dr. Pepper' },
  { id: 'keurig', name: 'Keurig Green Mountain' },
  { id: 'molson_coors', name: 'Molson Coors' },
  { id: 'motts', name: "Mott's" },
  { id: 'ocean_spray', name: 'Ocean Spray' },
  { id: 'walmart', name: 'Walmart' },
]

export const ChecklistStatusDetailText: Record<any, string> = {
  NONE: '', // passthrough
  IN_REVIEW: 'In review',
  MISSING_INFORMATION: 'Missing information',
  NOT_SUBMITTED: 'Not submitted',
  CONTACT_CLOUDTRUCKS_REPRESENTATIVE: 'Contact your CloudTrucks representative',
  COMPLETE_PREVIOUS_TASKS: 'Complete previous tasks',
  FLEET_NOT_ELIGIBLE: 'Fleet not eligible',
}

export const STATES = [
  { name: 'Alabama', abbreviation: 'AL' },
  { name: 'Arizona', abbreviation: 'AZ' },
  { name: 'Arkansas', abbreviation: 'AR' },
  { name: 'California', abbreviation: 'CA' },
  { name: 'Colorado', abbreviation: 'CO' },
  { name: 'Connecticut', abbreviation: 'CT' },
  { name: 'Delaware', abbreviation: 'DE' },
  { name: 'District Of Columbia', abbreviation: 'DC' },
  { name: 'Florida', abbreviation: 'FL' },
  { name: 'Georgia', abbreviation: 'GA' },
  { name: 'Idaho', abbreviation: 'ID' },
  { name: 'Illinois', abbreviation: 'IL' },
  { name: 'Indiana', abbreviation: 'IN' },
  { name: 'Iowa', abbreviation: 'IA' },
  { name: 'Kansas', abbreviation: 'KS' },
  { name: 'Kentucky', abbreviation: 'KY' },
  { name: 'Louisiana', abbreviation: 'LA' },
  { name: 'Maine', abbreviation: 'ME' },
  { name: 'Maryland', abbreviation: 'MD' },
  { name: 'Massachusetts', abbreviation: 'MA' },
  { name: 'Michigan', abbreviation: 'MI' },
  { name: 'Minnesota', abbreviation: 'MN' },
  { name: 'Mississippi', abbreviation: 'MS' },
  { name: 'Missouri', abbreviation: 'MO' },
  { name: 'Montana', abbreviation: 'MT' },
  { name: 'Nebraska', abbreviation: 'NE' },
  { name: 'Nevada', abbreviation: 'NV' },
  { name: 'New Hampshire', abbreviation: 'NH' },
  { name: 'New Jersey', abbreviation: 'NJ' },
  { name: 'New Mexico', abbreviation: 'NM' },
  { name: 'New York', abbreviation: 'NY' },
  { name: 'North Carolina', abbreviation: 'NC' },
  { name: 'North Dakota', abbreviation: 'ND' },
  { name: 'Ohio', abbreviation: 'OH' },
  { name: 'Oklahoma', abbreviation: 'OK' },
  { name: 'Oregon', abbreviation: 'OR' },
  { name: 'Pennsylvania', abbreviation: 'PA' },
  { name: 'Rhode Island', abbreviation: 'RI' },
  { name: 'South Carolina', abbreviation: 'SC' },
  { name: 'South Dakota', abbreviation: 'SD' },
  { name: 'Tennessee', abbreviation: 'TN' },
  { name: 'Texas', abbreviation: 'TX' },
  { name: 'Utah', abbreviation: 'UT' },
  { name: 'Vermont', abbreviation: 'VT' },
  { name: 'Virginia', abbreviation: 'VA' },
  { name: 'Washington', abbreviation: 'WA' },
  { name: 'West Virginia', abbreviation: 'WV' },
  { name: 'Wisconsin', abbreviation: 'WI' },
  { name: 'Wyoming', abbreviation: 'WY' },
]

export const FRACTION_REGEX = /^(?:\d+ )?\d+\/\d+$|^\d+$/

export const GENERIC_ERROR = 'Something went wrong'
export const GENERIC_ERROR_CONTACT_US = 'Something went wrong. Please contact us.'
export const PO_BOX_ERROR_MESSAGE = 'PO Box is not allowed'
export const PO_BOX_VALIDATION_ERROR = 'PO Box address is not allowed'

export const ZENDESK_URL = 'https://cloudtrucks.zendesk.com'
