import { FormControlLabelProps } from '@mui/material'
import { forwardRef } from 'react'

import { HandlerOf } from '../../helpers/typeHelper'
import { ErrorMessage, FormControlLabelStyled, StyledCheckbox } from './Checkbox.styles'

export interface CheckboxProps {
  value?: boolean
  onChange?: HandlerOf<boolean>
  label: React.ReactNode
  name?: string
  error?: React.ReactElement | string | boolean
  disabled?: boolean
  className?: string
  inputProps?: any
}

export const Checkbox = forwardRef(
  ({ onChange, value, label, error, name, disabled, className, inputProps }: CheckboxProps, ref: any) => {
    const handleChange: FormControlLabelProps['onChange'] = (_, checked) => {
      onChange?.(checked)
    }

    return (
      <>
        <FormControlLabelStyled
          label={label}
          disabled={disabled}
          className={`${className} checkbox-label`}
          control={
            <StyledCheckbox
              ref={ref}
              checked={value}
              onChange={handleChange}
              name={name}
              $error={Boolean(error)}
              inputProps={inputProps}
            />
          }
        />
        {error && error !== true ? <ErrorMessage>{error}</ErrorMessage> : null}
      </>
    )
  }
)
