import { format, utcToZonedTime } from 'date-fns-tz'
import _, { uniqBy } from 'lodash'
import queryString from 'query-string'
import { DateRangeValueType } from 'shared/components/DateRangeSelector/DateRangeSelector'
import { renderUserDropdownLabel } from 'shared/components/Dropdown/TeamMembersDropdown'
import { ANYWHERE } from 'shared/helpers/constants'
import { QueryFilter } from 'shared/models/job'
import { errorD1, kellyGreenD1, marigoldOrange, midGray } from 'shared/theme/colors'

import { EquipmentType } from '../models/equipment'
import { Job, JobsSearchFilter, JobStatusDetail, Load, LoadStop, LoadStopType } from '../models/jobs'
import { Truck } from '../models/truck'
import { JobFiltersTypes, SelectedJobFiltersValuesType } from '../pages/Jobs/JobFilters/jobFiltersConstants'

export const equipmentBookingChanged = (
  queryFilter: QueryFilter,
  newSelectedEquipment: EquipmentType[],
  newBookingTypes: string[]
) => {
  // return false if selected equipment equals queryFilter equipment,
  // selected booking type equals queryFilter booking type or all checkboxes are checked
  if (
    (_.isEqual(newSelectedEquipment.sort(), [...(queryFilter.equipment || [])].sort()) &&
      ((newBookingTypes.length === 2 && queryFilter.booking_type === 'ALL') ||
        (newBookingTypes.length === 1 && newBookingTypes[0] === queryFilter.booking_type))) ||
    (newSelectedEquipment.length === 3 && newBookingTypes.length === 2)
  ) {
    return false
  }
  return true
}

export const isOpenEndedSearch = (dropoffLocation: string) => {
  return dropoffLocation === '' || dropoffLocation.toUpperCase() === ANYWHERE
}

export const getJobStatusDetailColor = (jobStatusDetail: JobStatusDetail): string => {
  switch (jobStatusDetail) {
    case JobStatusDetail.BIDDING_REQUIRED:
    case JobStatusDetail.BOOKED_OFFLINE:
    case JobStatusDetail.ACTIVE_NEGOTIATION:
    case JobStatusDetail.REVIEWING_RATE_CONFIRMATION:
    case JobStatusDetail.JOB_APPROVED:
    case JobStatusDetail.PENDING_DELIVERY_VERIFICATION:
    case JobStatusDetail.BROKER_SETUP_REQUIRED:
    case JobStatusDetail.AWAITING_RATE_CONFIRMATION:
      return marigoldOrange
    case JobStatusDetail.IN_PROGRESS:
    case JobStatusDetail.DELIVERY_VERIFICATION_ACCEPTED:
    case JobStatusDetail.PAYMENT_REQUIRED:
    case JobStatusDetail.PENDING_PAYMENT_VERIFICATION:
    case JobStatusDetail.PAYMENT_ISSUED:
    case JobStatusDetail.REIMBURSED:
    case JobStatusDetail.NF_BOOKED:
    case JobStatusDetail.NF_COMPLETED:
      return kellyGreenD1
    case JobStatusDetail.ABORTED:
    case JobStatusDetail.CANCELED:
      return midGray
    case JobStatusDetail.DELIVERY_VERIFICATION_REJECTED:
    case JobStatusDetail.NF_REMOVED:
      return errorD1
    default:
      return ''
  }
}

export const getTripRate = (loadTripRate: number | string, confirmedRate?: number): number => {
  if (confirmedRate) {
    return confirmedRate
  }
  return loadTripRate && loadTripRate !== '0.00' ? Number(loadTripRate) : 0
}

export const formatTripRate = (tripRate: number): string => {
  return Number(tripRate).toLocaleString('en-US', {
    style: 'currency',
    currency: 'USD',
  })
}

export const getTripRatePerMile = (tripRate: number, tripDistance: number): string => {
  return tripRate && tripDistance ? Number(tripRate / tripDistance).toFixed(2) : '0'
}

export const getLoadStopOrigin = (load: Load) => {
  if (!load) {
    return null
  }
  const origin = load.stops.find((stop) => stop.type === LoadStopType.ORIGIN) as LoadStop
  return origin || null
}

export const getJobLoadStopOrigin = (job: Job) => {
  if (!job) {
    return null
  }
  return getLoadStopOrigin(job.load)
}

export const convertComponentJobFiltersToSearchFilters = (
  nonDateRangeFilters?: SelectedJobFiltersValuesType,
  dateRange?: DateRangeValueType
): JobsSearchFilter => {
  return {
    driver: nonDateRangeFilters?.[JobFiltersTypes.DRIVER],
    truck: nonDateRangeFilters?.[JobFiltersTypes.TRUCK],
    broker: nonDateRangeFilters?.[JobFiltersTypes.BROKER],
    date_range_start: dateRange?.[0] === 'ALL' ? 'ALL' : dateRange?.[0]?.toISOString(),
    date_range_end: dateRange?.[1] === 'ALL' ? 'ALL' : dateRange?.[1]?.toISOString(),
  }
}

export const serializeSearchForUrlParams = (search: JobsSearchFilter): string => {
  const params = {
    driver: search.driver,
    truck: search.truck,
    date_range_start: search.date_range_start,
    date_range_end: search.date_range_end,
    broker: search.broker,
  }

  return queryString.stringify(params)
}

function parseIfString(params: queryString.ParsedQuery<string>, key: string): Partial<JobsSearchFilter> | {} {
  if (typeof params[key] === 'string') {
    return { [key]: params[key] }
  }

  return {}
}

export const parseUrlParamsForJobsSearchFilters = (searchStr: string): Partial<JobsSearchFilter> => {
  const params = queryString.parse(searchStr)

  return {
    ...parseIfString(params, 'driver'),
    ...parseIfString(params, 'truck'),
    ...parseIfString(params, 'date_range_start'),
    ...parseIfString(params, 'date_range_end'),
    ...parseIfString(params, 'broker'),
  }
}

const driverFilterFn = (job: Job, driverSearchVal: string | null | undefined) => {
  if (driverSearchVal === 'ALL' || !driverSearchVal) {
    return true
  }

  if (driverSearchVal === 'NOT_ASSIGNED') {
    return !job.assigned_to?.id
  }

  return job.assigned_to?.id === driverSearchVal
}

const truckFilterFn = (job: Job, truckSearchVal: string | null | undefined) => {
  if (truckSearchVal === 'ALL' || !truckSearchVal) {
    return true
  }

  if (truckSearchVal === 'NOT_ASSIGNED') {
    return !job.assigned_truck?.id
  }

  return job.assigned_truck?.id === truckSearchVal
}

const brokerFilterFn = (job: Job, brokerSearchVal: string | null | undefined) => {
  if (brokerSearchVal === 'ALL' || !brokerSearchVal) {
    return true
  }

  if (brokerSearchVal === 'NOT_ASSIGNED') {
    return !job.load.broker
  }

  return job.load.broker === brokerSearchVal
}

const dateRangeStartFilterFn = (
  dateStart: string | undefined,
  dateStartTimezone: string | undefined,
  dateRangeStartSearchVal: string | null | undefined
) => {
  if (dateRangeStartSearchVal === 'ALL' || !dateRangeStartSearchVal) {
    return true
  }

  if (!dateStart || !dateStartTimezone) {
    return false
  }

  const originLocalDay = format(utcToZonedTime(dateStart, dateStartTimezone), 'yyyy-MM-dd')
  const searchStartDay = format(new Date(dateRangeStartSearchVal), 'yyyy-MM-dd')

  return originLocalDay >= searchStartDay
}

const dateRangeEndFilterFn = (
  dateStart: string | undefined,
  dateStartTimezone: string | undefined,
  dateRangeEndSearchVal: string | null | undefined
) => {
  if (dateRangeEndSearchVal === 'ALL' || !dateRangeEndSearchVal) {
    return true
  }

  if (!dateStart || !dateStartTimezone) {
    return false
  }

  const originLocalDay = format(utcToZonedTime(dateStart, dateStartTimezone), 'yyyy-MM-dd')
  const searchEndDay = format(new Date(dateRangeEndSearchVal), 'yyyy-MM-dd')

  return originLocalDay <= searchEndDay
}

export const jobsFilterFn =
  (search: JobsSearchFilter) =>
  (job: Job): boolean => {
    const jobOriginStop = getJobLoadStopOrigin(job)

    return (
      driverFilterFn(job, search.driver) &&
      truckFilterFn(job, search.truck) &&
      brokerFilterFn(job, search.broker) &&
      dateRangeStartFilterFn(
        jobOriginStop?.date_start,
        jobOriginStop?.date_start_timezone_name,
        search.date_range_start
      ) &&
      dateRangeEndFilterFn(
        jobOriginStop?.date_start,
        jobOriginStop?.date_start_timezone_name,
        search.date_range_end
      )
    )
  }

const driverOptions = (jobs: Job[]) =>
  jobs.map((job) => ({
    label: `${job.assigned_to?.first_name} ${job.assigned_to?.last_name}` || 'Not assigned',
    dropDownLabel: job.assigned_to
      ? renderUserDropdownLabel(`${job.assigned_to?.first_name} ${job.assigned_to?.last_name}`)
      : 'Not assigned',
    value: job.assigned_to?.id || 'NOT_ASSIGNED',
  }))

const formatTruckOptionLabel = (truck: Truck) =>
  truck.vehicleNumber ||
  `${truck.vehicleYear} ${truck.vehicleMake} ${truck.vehicleModel} VIN-${truck.vinNumber?.slice(-6)}`

const truckOptions = (jobs: Job[], trucks: Truck[]) => [
  ...jobs.map((job) => {
    const truck = trucks.find(
      (t) => t.vinNumber === job.assigned_truck?.vin_number || t.id === job.assigned_truck?.id
    )
    return {
      label: truck ? formatTruckOptionLabel(truck) : 'Not assigned',
      value: truck && job.assigned_truck?.id ? job.assigned_truck?.id : 'NOT_ASSIGNED',
    }
  }),
  ...trucks.map((truck) => ({
    label: formatTruckOptionLabel(truck),
    value: truck.id || 'NOT_ASSIGNED',
  })),
]

const brokerOptions = (jobs: Job[]) =>
  jobs.map((job) => ({
    label: job.load.broker_name || 'Not assigned',
    value: job.load.broker || 'NOT_ASSIGNED',
  }))

export const getJobFiltersOptions = (
  jobs: Job[],
  trucks: Truck[],
  filterType: JobFiltersTypes
): { value: string; label: string }[] => {
  const options =
    filterType === JobFiltersTypes.DRIVER
      ? driverOptions(jobs)
      : filterType === JobFiltersTypes.TRUCK
      ? truckOptions(jobs, trucks)
      : brokerOptions(jobs)

  return uniqBy(
    [
      // Always add NOT_ASSIGNED option as first option
      { label: 'Not assigned', value: 'NOT_ASSIGNED' },
      ...options.sort((a, b) => (a.value === '' ? -1 : b.value === '' ? 1 : a.label.localeCompare(b.label))),
    ],
    'value'
  )
}
