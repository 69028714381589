import axios from 'axios'
import * as HttpStatus from 'http-status-codes'
import { getVGSDomain } from 'shared/config/config'
import { CamelToSnakeCaseNested } from 'shared/helpers/typeHelper'
import { toCamelCase, toSnakeCase } from 'shared/helpers/utils'
import { OnboardingBackgroundCheckStatus } from 'shared/models/backgroundCheck'
import {
  DriverStatus,
  DriverStatusHuman,
  EmergencyContact,
  ListUser as CamelCasedListUser,
  ShirtSize,
  UpdateUserPayload,
  User as CamelCasedUser,
  UserRoles,
  VendorStatus,
  VendorUserStatus,
} from 'shared/models/user'

import { CoreDocument } from './document'
import { EquipmentType } from './equipment'
import { Address, Team } from './models'
import {
  ActivateUserPayload,
  CreateUserPayload,
  DriverInsuranceDocumentsPayload,
  InviteCreateUserPayload,
} from './payloads'
import { Permissions } from './permission'
import { TruckPayload } from './truck'

export interface TeamMembership {
  id: string
  driver_status: DriverStatus
  driver_status_human: DriverStatusHuman
  is_team_admin: boolean
  is_team_driver: boolean
  is_active: boolean
  has_dispatch_service_feature: boolean
  has_dispatch_service_free_feature: boolean
  has_truck_lease_feature: boolean
  driver_date_terms_accepted: string | null
  driver_employment_history_submitted: string | null
}

export interface ListUser {
  id: string
  first_name: string
  middle_name?: string
  last_name: string
  mobile?: string
  mobile_verified?: boolean
  date_joined?: string
  is_active?: boolean
  is_staff?: boolean
  email?: string
  email_verified?: boolean
  is_team_admin?: boolean
  is_team_driver?: boolean
  deprecated_is_team_admin?: boolean
  deprecated_is_team_driver?: boolean
  last_login?: string
  roles?: UserRoles[]
  default_team_membership?: TeamMembership
  shipping_address: Address | null
  background_check_status?: OnboardingBackgroundCheckStatus
}

export interface User extends ListUser {
  driver: Driver
  has_tenstreet_document?: boolean
  date_close_account_requested?: string
  team?: string
  invitedUser?: Partial<User>
  location?: Location
  featureFlags?: Record<string, boolean>
  intercom_identity?: string
  search_loading_message?: string
  equipment_types?: EquipmentType[]
  permissions?: Permissions
  had_privilege_denied_detail?: string
  had_privilege_suspended_detail?: string
  has_employment_jobs?: boolean
  has_recorded_terms_on_stripe?: boolean
  employment_jobs_document?: string
  vendor_membership?: VendorUser
}

export interface VendorUser {
  id: string
  status: VendorUserStatus
  vendor: Vendor
}

export interface Vendor {
  id: string
  status: VendorStatus
  legal_business_name: string
  phone: string
  website: string | null
  billing_address: Address
}

export interface Driver {
  dob?: string
  status?: DriverStatus
  license_expiration?: string
  license_issued?: string
  license_number?: string
  license_state?: string
  license_front?: string
  license_back?: string
  license_document?: CamelToSnakeCaseNested<CoreDocument<{}>> & {
    metadata: {
      DRIVER_LICENSE_ISSUE_DATE: string
      DRIVER_LICENSE_NUMBER: string
      DRIVER_LICENSE_STATE: string
    }
  }
  date_created?: string
  date_updated?: string
  is_driver_application_form_complete?: boolean
  is_driver_info_complete?: boolean
  is_license_info_complete?: boolean
  is_emergency_contact_complete?: boolean
  date_terms_accepted?: string
  medical_card?: string
  medical_card_document?: CamelToSnakeCaseNested<CoreDocument<{}>>
  medical_card_expiration?: string
  is_medical_card_valid?: boolean
  emergency_contact?: EmergencyContact
  shirt_size?: ShirtSize

  years_dry_van?: number
  years_flatbed?: number
  years_reefer?: number

  had_at_fault_incident?: boolean
  had_major_violation?: boolean
  had_privilege_denied?: boolean
  had_privilege_suspended?: boolean
  assigned_truck?: TruckPayload
  has_ssn?: boolean

  is_early_cycle_driver?: boolean
  checkr_candidate_id?: string
  checkr_candidate_url?: string
  checkr_has_active_consent?: boolean
  checkr_drug_screening_appointment_url?: string
  supported_freight_types?: EquipmentType[]
}

export interface Location {
  lat: number
  lng: number
  address: string
}

export interface CreateUserInfo {
  first_name: string
  last_name: string
  mobile: string
  email: string
  valid_email?: boolean
  email_validation_message?: string
  valid_mobile?: boolean
  mobile_validation_message?: string
  referral_code?: string
  referral_source?: string
}

// API endpoints

export function createUser(payload: CreateUserPayload | InviteCreateUserPayload): Promise<User> {
  const url = '/api/v1/users/'

  return axios
    .post(url, payload)
    .then((response) => {
      if (response && response.status === HttpStatus.CREATED) {
        return response.data
      }
      throw response.data
    })
    .catch((error) => {
      throw error?.response?.data || error?.response || error
    })
}

export function createUserValidation(payload: CreateUserPayload | InviteCreateUserPayload): Promise<User> {
  const url = '/api/v1/users/create/validate/'

  return axios
    .post(url, payload)
    .then((response) => {
      return response.data
    })
    .catch((error) => {
      throw error?.response?.data || error?.response || error
    })
}

export function deactivateUser(userId: string | number) {
  const url = `/api/v1/users/${userId}/deactivate/`

  return axios.post(url).then((response) => {
    return response.data
  })
}

export function sendInvite(user: User) {
  const url = `/api/v1/users/${user.id}/invite/`
  return axios.post(url, user)
}

export function signUp(user: CreateUserInfo, welcomeEmail = true): Promise<User> {
  return axios
    .post(`/api/v1/users/signup/?welcome_email=${welcomeEmail}`, JSON.stringify(user))
    .then((response) => {
      if (response.status === HttpStatus.CREATED) return response.data
      throw response.data
    })
    .catch((error) => {
      throw error.response
    })
}

export function getCurrentUser(): Promise<User & { team_object: Team }> {
  const url = `/api/v3/users/me/?slim_member=true`
  return axios.get(url).then((response) => {
    if (response.status === 200) {
      return {
        ...response.data,
        featureFlags: response.data.feature_flags,
      }
    }
    throw response.data
  })
}

export function getUser(user_id: string): Promise<User> {
  const url = `/api/v1/users/${user_id}/`
  return axios.get(url).then((response) => {
    return response.data
  })
}

export function activateUser(payload: ActivateUserPayload) {
  const url = `/api/v1/users/activate/ `
  return axios.post(url, payload)
}

export function updateEmergencyContactInfo(
  userId: string,
  emergencyContact: EmergencyContact
): Promise<User> {
  const url = `/api/v1/users/${userId}/emergency-contact/`
  return axios.post(url, emergencyContact).then((response) => {
    if (response.status === HttpStatus.OK) {
      return response.data
    }
    throw response.data
  })
}

export function confirmToken(token: string) {
  const url = `/api/v1/users/confirm-temp-web-token/ `
  return axios.post(url, { token })
}

export function uploadCDL(userId: string, payload: FormData) {
  const url = `/api/v1/users/${userId}/license/`
  return axios.post(url, payload)
}

// CDL url is in the format:
// https://storage.googleapis.com/documents-cloudtrucks/
//     media/teams/:id/users/:id/license/front/:id/FILENAME.png
//     ?Expires=...&GoogleAccessId=...&Signature=...
export async function fetchCDLImage(url: string): Promise<File | undefined> {
  const fileName = url.split('?')[0].split('/').pop() || 'license.jpg'
  const mimeType = 'image/' + (fileName?.split('.').pop() || 'jpg')

  return axios({ url: url, method: 'GET', responseType: 'blob' })
    .then((response) => {
      if (response.status === HttpStatus.OK) {
        return new File([response.data], fileName, { type: mimeType })
      }

      throw new Error('There was an error fetching the image.')
    })
    .catch((err) => {
      throw err.response
    })
}

export function createInsuranceDocumentation(documentation: DriverInsuranceDocumentsPayload) {
  const url = `/api/v1/users/${documentation.userId}/insurance/`
  const config = {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  }

  return axios.post(url, documentation.formData, config).then((response) => {
    if (response.status === HttpStatus.NO_CONTENT) {
      return response.data
    }
  })
}

export function updateUserInfo(
  user_id: string,
  payload: UpdateUserPayload,
  options: { vgs?: boolean; v3Api?: boolean } = {}
): Promise<User> {
  const url = `${options.vgs ? getVGSDomain() : ''}/api/v${options.v3Api ? '3' : '1'}/users/${user_id}/`
  const patchOptions = options.vgs ? { withCredentials: true } : undefined
  return axios
    .patch(url, payload, patchOptions)
    .then((response) => {
      if (response.status === HttpStatus.OK) {
        return response.data
      }
      throw response.data
    })
    .catch((error) => {
      throw error?.response?.data || error?.response || error
    })
}

export { UserRoles, DriverStatus, OnboardingBackgroundCheckStatus, ShirtSize }

export type { EmergencyContact }

export function camelizeUser(user: User): CamelCasedUser {
  return toCamelCase(user) as CamelCasedUser
}

export function snakeCaseUser(user: CamelCasedUser): User {
  return toSnakeCase(user) as User
}

export function camelizeListUser(user: ListUser): CamelCasedListUser {
  return toCamelCase(user) as CamelCasedListUser
}

export function snakeCaseListUser(user: CamelCasedListUser): ListUser {
  return toSnakeCase(user) as ListUser
}
