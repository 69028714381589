import { SvgIcon } from '@mui/material'
import React from 'react'
import styled, { css } from 'styled-components'

import { bluefa, cyan, royalBlue } from '../../theme/colors'
import { centeredContent } from '../../theme/mixins'

export interface OutlinedIconBaseProps {
  variant?: 'outlined' | 'filled'
  className?: string
  isLarge?: boolean
}

export interface OutlinedIconIProps {
  Icon: typeof SvgIcon
  Image?: never
}

export interface OutlinedIconSrcProps {
  Image: React.ReactNode
  Icon?: never
}

type OutlinedIconProps = OutlinedIconIProps | OutlinedIconSrcProps

const Wrap = styled.div<{
  $isFlex?: boolean
  $isFilled?: boolean
  $isLarge?: boolean
  $isOutlined?: boolean
}>`
  width: 65px !important;
  height: 65px !important;
  padding: 10px;
  border-radius: 50%;
  display: block !important;
  align-items: center;
  justify-content: center;
  margin: 0 auto;

  ${(p) =>
    p.$isFlex &&
    css`
      display: flex;
    `}

  ${(p) =>
    p.$isOutlined &&
    css`
      color: ${royalBlue};
      background: ${bluefa};
    `}

  ${(p) =>
    p.$isFilled &&
    css`
      color: ${cyan};
      border: 3px solid ${cyan};
      background: white;
    `}

  ${(p) =>
    p.$isLarge &&
    css`
      width: 96px;
      height: 96px;
    `}
`

export function OutlinedIcon({
  Icon,
  variant,
  className = '',
  Image,
  isLarge,
}: OutlinedIconBaseProps & OutlinedIconProps) {
  const isFilled = variant === 'filled'

  if (Image) {
    return (
      <Wrap $isFlex $isFilled={isFilled} $isOutlined={!isFilled} $isLarge={isLarge} className={className}>
        {Image}
      </Wrap>
    )
  }

  if (Icon) {
    return (
      <Wrap as={Icon} $isFilled={isFilled} $isOutlined={!isFilled} $isLarge={isLarge} className={className} />
    )
  }

  return null
}

const StyledOutlinedImgIconWrap = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`

const StyledOutlinedImgIcon = styled.div`
  ${centeredContent};
  background-color: white;
  border-radius: 50px;
`

export function OutlinedImgIcon({ src }: { src: string }) {
  return (
    <StyledOutlinedImgIconWrap>
      <StyledOutlinedImgIcon>
        <img src={src} alt="" />
      </StyledOutlinedImgIcon>
    </StyledOutlinedImgIconWrap>
  )
}
