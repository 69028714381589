import { sizes, variants } from '../../theme'
import { darkRoyalBlue, royalBlue, white } from '../../theme/colors'
import { hexWithOpacity } from '../../theme/utils'

export const PADDING = {
  [sizes.small]: '8px 12px',
  [sizes.medium]: '12px 12px',
  [sizes.large]: '14px 16px',
  [sizes.extraLarge]: '16px 20px',
}

export const ADDITIONAL_PADDING = {
  [sizes.small]: 0, // total 12
  [sizes.medium]: 12, // total 24
  [sizes.large]: 40, // total 56
  [sizes.extraLarge]: 68, // total 88
}

export const DIMENSIONS = {
  [sizes.small]: 24,
  [sizes.medium]: 34,
  [sizes.large]: 44,
  [sizes.extraLarge]: 56,
}

export const FONT_SIZE = {
  [sizes.small]: 12,
  [sizes.medium]: 13,
  [sizes.large]: 15,
  [sizes.extraLarge]: 16,
}

export const TEXT_BUTTON_FONT_SIZE = {
  [sizes.small]: 12,
  [sizes.medium]: 14,
  [sizes.large]: 16,
  [sizes.extraLarge]: 16,
}

export const ICON_SIZE = {
  [sizes.small]: 16,
  [sizes.medium]: 16,
  [sizes.large]: 24,
  [sizes.extraLarge]: 24,
}

export const COLORS = {
  REGULAR: {
    [variants.primary]: white,
    [variants.secondary]: royalBlue,
    [variants.flat]: royalBlue,
    [variants.text]: royalBlue,
  },
  HOVER: {
    [variants.primary]: white,
    [variants.secondary]: royalBlue,
    [variants.flat]: royalBlue,
    [variants.text]: darkRoyalBlue,
  },
  ACTIVE: {
    [variants.primary]: white,
    [variants.secondary]: royalBlue,
    [variants.flat]: royalBlue,
    [variants.text]: darkRoyalBlue,
  },
}

export const BACKGROUND_COLORS = {
  REGULAR: {
    [variants.primary]: royalBlue,
    [variants.secondary]: 'transparent',
    [variants.flat]: 'transparent',
    [variants.text]: 'none',
  },
  HOVER: {
    [variants.primary]: darkRoyalBlue,
    [variants.secondary]: hexWithOpacity(royalBlue, 0.05),
    [variants.flat]: hexWithOpacity(royalBlue, 0.05),
    [variants.text]: 'transparent',
  },
  ACTIVE: {
    [variants.primary]: darkRoyalBlue,
    [variants.secondary]: hexWithOpacity(royalBlue, 0.05),
    [variants.flat]: hexWithOpacity(royalBlue, 0.05),
    [variants.text]: 'transparent',
  },
}

export const BORDER_COLORS = {
  REGULAR: {
    [variants.primary]: royalBlue,
    [variants.secondary]: royalBlue,
    [variants.flat]: 'transparent',
    [variants.text]: 'transparent',
  },
  HOVER: {
    [variants.primary]: darkRoyalBlue,
    [variants.secondary]: royalBlue,
    [variants.flat]: 'transparent',
    [variants.text]: 'transparent',
  },
  ACTIVE: {
    [variants.primary]: darkRoyalBlue,
    [variants.secondary]: royalBlue,
    [variants.flat]: 'transparent',
    [variants.text]: 'transparent',
  },
}
