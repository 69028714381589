import { ReactPortal, useEffect, useRef, useState } from 'react'
import ReactDOM from 'react-dom'
import { getScrollbarSize } from 'shared/helpers/getScrollbarSize'
import { Handler } from 'shared/helpers/typeHelper'
import { css, styled } from 'styled-components'

import Overlay from '../Overlay/Overlay'
import { useDrawerProvider } from './DrawerContext'
import { DrawerHeader } from './DrawerHeader'

function changePaddings(padding: number) {
  document.querySelectorAll<HTMLElement>('body, .mui-fixed').forEach((el) => {
    el.style.paddingRight = `${padding}px`
  })
}

export interface DrawerProps {
  isOpen: boolean
  children: (wrapRef: HTMLDivElement | null) => React.ReactNode
  onClose: Handler
  openLinkAddress?: string
  header?: React.ReactNode
  className?: string
  width?: number
  withOverlay?: boolean
  customCloseHandler?: (isFromCloseButton: boolean, close: () => void) => void
}

export function Drawer({
  isOpen: isInitiallyOpen,
  children,
  onClose,
  openLinkAddress,
  header,
  className,
  width,
  withOverlay,
  customCloseHandler,
}: DrawerProps): ReactPortal | null {
  const wrapRef = useRef<HTMLDivElement>(null)
  const [isOpen, setIsOpen] = useState(false)
  const { generateDrawerWrap, cleanUpDrawerWrap } = useDrawerProvider()
  const [transitionIn, setTransitionIn] = useState(false)
  const [drawerElement, setDrawerElement] = useState<HTMLDivElement | null>(null)

  useEffect(() => {
    if (isOpen) {
      setDrawerElement(generateDrawerWrap())
      setTimeout(() => setTransitionIn(true), 10)
    }

    if (!isOpen) {
      setTransitionIn(false)
      setTimeout(() => {
        cleanUpDrawerWrap(drawerElement)
        setDrawerElement(null)
      }, 300)
    }
    // only isOpen should control those
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isOpen])

  useEffect(() => {
    if (isInitiallyOpen) {
      const timeout = setTimeout(() => {
        setIsOpen(true)
        if (document.body.scrollHeight > document.body.clientHeight) {
          changePaddings(getScrollbarSize())
        }
        document.body.classList.add('overflow-hidden')
      }, 10)

      return () => clearTimeout(timeout)
    }
  }, [isInitiallyOpen])

  function closeDrawer() {
    setIsOpen(false)
    setTimeout(() => {
      document.body.classList.remove('overflow-hidden')
      changePaddings(0)
      onClose()
    }, 400)
  }

  function handleClose(isFromCloseButton: boolean) {
    if (customCloseHandler) {
      customCloseHandler(isFromCloseButton, () => closeDrawer())
    } else {
      closeDrawer()
    }
  }

  if (!isInitiallyOpen || !drawerElement) return null

  return ReactDOM.createPortal(
    <>
      <DrawerWrapper $transitionIn={transitionIn} $width={width} className={className} ref={wrapRef}>
        <DrawerHeader onClose={() => handleClose(true)} openLinkAddress={openLinkAddress}>
          {header}
        </DrawerHeader>
        <DrawerContent className="drawer-content">{children(wrapRef.current)}</DrawerContent>
      </DrawerWrapper>
      {withOverlay && <StyledOverlay onClick={() => handleClose(false)} />}
    </>,
    drawerElement
  )
}

const DrawerWrapper = styled.div<{
  $transitionIn?: boolean
  $positionExpandedUnderToolbar?: boolean
  $background?: string
  $width?: number
}>`
  position: fixed;
  z-index: 15;
  top: 0;
  right: 0;
  bottom: 0;
  width: 100%;
  max-width: ${({ $width }) => $width ?? 705}px;
  transform: translateX(100%);
  transition: transform 0.3s;
  background: ${({ $background }) => $background ?? 'white'};
  box-shadow: 0 2px 12px 5px rgba(0, 0, 0, 0.06);
  overflow-y: auto;
  overflow-x: hidden;

  display: grid;
  grid-template-columns: minmax(0, 1fr);
  grid-template-rows: auto 1fr;
  box-sizing: border-box;
  grid-template-areas:
    'drawer-header'
    'drawer-content';

  ${(p) =>
    p.$transitionIn &&
    css`
      transform: translateX(0);
    `}

  height: 100%;

  .drawer-header {
    max-height: 120px !important;
  }

  .drawer-content {
    max-height: calc(100% - 120px) !important;
  }
`

const DrawerContent = styled.div`
  grid-area: drawer-content;
`

const StyledOverlay = styled(Overlay)`
  background-color: rgba(0, 0, 0, 0.05);
`
