import { forwardRef } from 'react'
import * as React from 'react'

import { Handler, HandlerOf } from '../../helpers/typeHelper'
import { warningD1 } from '../../theme/colors'
import { Text } from '../Text/Text'
import { Arrow, DropdownContainer, ErrorMessage, Label, Select, StaticLabel } from './Dropdown.styles'
import { DropDownOpener } from './DropdownOpener'

export const DEFAULT_LABEL = 'Select'

export interface DropdownOption<T> {
  label: React.ReactNode
  value?: T
  dropDownLabel?: React.ReactNode
  onClick?: Handler
  renderer?: (d: DropdownOption<T>, closeOption: Handler) => React.ReactNode
}

export interface DropdownMonoselectProps<T> {
  options: Array<DropdownOption<T>>
  onChange?: HandlerOf<T>
  onBlur?: Handler
  // If dropdown is used inside a form, react-hook-form will override the onBlur handler, this is a fallback
  onNonFormBlur?: Handler
  onToggle?: HandlerOf<boolean>
  value?: T
  className?: string
  isInitiallyOpen?: boolean
  closeOnClick?: boolean
  error?: React.ReactElement | string | boolean
  warning?: string | React.ReactElement
  label?: React.ReactNode
  optionsLabel?: React.ReactNode
  emptyOptionsLabel?: React.ReactNode
  staticLabel?: React.ReactNode
  noMargin?: boolean
  noPadding?: boolean
  isFullWidth?: boolean
  dataTestId?: string
  bottom?: number
  width?: number | string
  containerMaxWidth?: string
  align?: 'left' | 'right'
  position?: 'top' | 'bottom'
  maxHeight?: number
  backgroundColor?: string
  labelColor?: string
  isDisabled?: boolean
  optionsMargin?: string
  fontSize?: string
  renderOpener?: (isOpen: boolean) => React.ReactNode
  formatLabel?: (label: string) => React.ReactNode
  addOptionsFilter?: boolean
  bottomAction?: React.ReactNode
  isMultiselect?: false
}

type FormatLabelMultiselectType = (labels: string[]) => React.ReactNode

export type DropdownMultiselectProps<T> = Omit<
  DropdownMonoselectProps<T>,
  'value' | 'onChange' | 'formatLabel'
> & {
  value?: T[]
  onChange?: HandlerOf<T[]>
  formatLabel?: FormatLabelMultiselectType
  isMultiselect: true
}

export type DropdownProps<T> = DropdownMonoselectProps<T> | DropdownMultiselectProps<T>

export const Dropdown = forwardRef(function Dropdown<T>(props: DropdownProps<T>, ref: any) {
  const {
    options,
    value,
    className,
    error,
    warning,
    label,
    staticLabel,
    isFullWidth,
    dataTestId,
    renderOpener,
    formatLabel,
    noMargin,
    noPadding,
    backgroundColor,
    labelColor,
    isDisabled,
    containerMaxWidth,
    isMultiselect,
  } = props

  // Monoselect
  const selectedMonoselectOption = options.find((option) => option.value === value)
  const selectedMonoselectLabel: React.ReactNode =
    (selectedMonoselectOption ? selectedMonoselectOption.label : label) ?? DEFAULT_LABEL

  // Multiselect
  const multiselectValue = isMultiselect ? ((value ?? []) as T[]) : undefined
  const selectedMultiselectOptions = options.filter((o) => !!o.value && multiselectValue?.includes(o.value))
  const selectedMultiselectLabel =
    selectedMultiselectOptions.length > 0
      ? selectedMultiselectOptions.map((o) => o.label).join(', ')
      : label ?? DEFAULT_LABEL

  // Unified
  const selectedOption = isMultiselect ? selectedMultiselectOptions.length > 0 : selectedMonoselectOption
  const showstaticLabel = Boolean(
    staticLabel && (isMultiselect ? selectedMultiselectOptions.length > 0 : selectedMonoselectOption)
  )

  // Format label
  const selectedLabel = isMultiselect ? selectedMultiselectLabel : selectedMonoselectLabel

  // Format label for monoselect
  const formatedLabelMonoselect =
    formatLabel && typeof selectedLabel === 'string' ? formatLabel(selectedLabel) : selectedLabel

  // Format label for multiselect
  const formatLabelMultiselect: FormatLabelMultiselectType | undefined = formatLabel as
    | FormatLabelMultiselectType
    | undefined
  const formatedLabelMultiselect =
    isMultiselect && formatLabelMultiselect
      ? formatLabelMultiselect(
          selectedMultiselectOptions.map((o) => (typeof o.label === 'string' ? o.label : ''))
        )
      : selectedLabel

  return (
    <DropdownContainer
      className={className}
      $noMargin={noMargin}
      $isFullWidth={isFullWidth}
      $minWidth="0"
      $maxWidth={containerMaxWidth}
    >
      <DropDownOpener {...props} ref={ref}>
        {(isOpen) =>
          renderOpener ? (
            renderOpener(isOpen)
          ) : (
            <>
              <Select
                className="dropdown-select"
                data-testid={dataTestId || 'select'}
                $isOpen={isOpen}
                $hasError={Boolean(error)}
                $backgroundColor={backgroundColor}
                $isDisabled={isDisabled}
                $hasWarning={Boolean(warning && !error)}
                $noPadding={noPadding}
              >
                <Label
                  className="dropdown-label"
                  $isSelected={!!selectedOption}
                  $hasStaticLabel={showstaticLabel}
                  $labelColor={labelColor}
                >
                  {isMultiselect ? formatedLabelMultiselect : formatedLabelMonoselect}
                </Label>
                {isDisabled ? null : <Arrow $isOpen={isOpen} />}
              </Select>
              {showstaticLabel && <StaticLabel> {staticLabel} </StaticLabel>}
              {warning && !error && (
                <Text variant="label3" fontColor={warningD1}>
                  {warning}
                </Text>
              )}
            </>
          )
        }
      </DropDownOpener>
      {error && error !== true && <ErrorMessage>{error}</ErrorMessage>}
    </DropdownContainer>
  )
})
