import { cyan, lightGray1, primaryBlack } from 'shared/theme/colors'
import { uppercaseLabel2, uppercaseLabel3 } from 'shared/theme/typography'
import styled, { css } from 'styled-components'

export const TabLink = styled.div<{ $isSelected?: boolean; $small?: boolean }>`
  ${uppercaseLabel2};
  text-decoration: none;
  color: ${lightGray1};
  opacity: 0.7;
  padding-bottom: 4px;
  border-bottom: 4px solid transparent;
  cursor: pointer;
  white-space: nowrap;

  &:hover {
    opacity: 1;
  }

  ${(p) =>
    p.$isSelected &&
    css`
      opacity: 1;
      color: ${primaryBlack};
      border-bottom-color: ${cyan};
    `}

  ${(p) =>
    p.$small &&
    css`
      ${uppercaseLabel3};
      opacity: 1;
      padding-bottom: 5px;
      border-bottom: 2px solid transparent;
      color: ${lightGray1};
      cursor: pointer;

      &:hover {
        color: ${primaryBlack};
      }
    `}

  ${(p) =>
    p.$small &&
    p.$isSelected &&
    css`
      border-color: ${cyan};
      color: ${primaryBlack};
    `}
`
