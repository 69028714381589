import { CamelToSnakeCaseNested } from 'shared/helpers/typeHelper'
import { FleetApplication, YesNo } from 'shared/models'
import {
  ConnectedIntegration,
  ConnectedIntegrationFieldComponent,
  ConnectedIntegrationFormAction,
  Integration,
  NotConnectedIntegration,
} from 'shared/models/connectedIntegration'
import { ConnectedIntegrationStatusEnum } from 'shared/models/integration'
import { Feature, FeatureId } from 'shared/models/productSubscription'
import { SortType } from 'shared/models/search'
import { BusinessType, TeamGreyList, TeamStatus } from 'shared/models/team'
import { TruckListSetting } from 'shared/models/truckList'

import { Environment } from '../config/config'
import { CreditApplicationStatus } from './cashSummary'
import {
  ProductSubscription,
  ProductSubscriptionBundleName,
  ProductSubscriptionItemStatus,
} from './productSubscription'
import { StripeAccount } from './stripe'
import { ListUser } from './user'
export { DriverStatus, OnboardingBackgroundCheckStatus, ShirtSize } from './user'
export type { User, Driver, EmergencyContact, Location, CreateUserInfo } from './user'
export type { BackgroundCheckResult } from 'shared/models/backgroundCheck'

export { YesNo }

export interface Authentication {
  userContact: string
  isAuthenticated: boolean
}

export interface CreateFleet {
  billing_address?: Address
  composition?: string
  dot_number?: string
  location_city?: string
  location_state?: string
  mc_number?: string
  name?: string
  legal_name_business?: string
  num_trailers_dry_van?: number
  num_trailers_flatbed?: number
  num_trailers_reefer?: number
  trucking_interest?: Array<TruckingInterest>
  bundle_name?: ProductSubscriptionBundleName | null
  is_l2o?: boolean | null
}

export enum TruckingInterest {
  OTR = 'OTR',
  LOCAL = 'LOCAL',
  REGIONAL = 'REGIONAL',
}

// TODO Rename Team to Fleet
export interface Team {
  id?: string
  name?: string
  is_approved?: boolean
  num_power_units?: number
  num_trailers?: number
  composition?: string
  dot_number?: string
  mc_number?: string
  location_city?: string
  location_state?: string
  referral_source?: string
  date_created?: string
  date_updated?: string
  date_esign_act_consent?: string
  num_trailers_dry_van?: string
  num_trailers_flatbed?: string
  num_trailers_reefer?: string
  stripe_account_created?: boolean
  stripe_account?: StripeAccount
  num_active_drivers: number
  billing_address: Address
  rates_visible_to_non_admins?: boolean
  members: Array<ListUser>
  product_subscription: ProductSubscription
  ct_mc_number: string
  plaid_assets_eligible?: boolean
  credit_status?: CreditApplicationStatus
  business_type?: BusinessType
  business_documentation?: string
  legal_name_business?: string
  legal_formation_state?: string
  legal_business_owner?: string
  is_credit_status_open_to_user?: boolean
  has_tax_id?: boolean
  is_tax_info_complete?: boolean
  entitlements?: Entitlement[]
  truck_list_setting: CamelToSnakeCaseNested<TruckListSetting>
  status: TeamStatus
  grey_list: CamelToSnakeCaseNested<TeamGreyList>
}

export interface Entitlement {
  name: Feature
  quantity: number
  featureId: FeatureId
  status: ProductSubscriptionItemStatus
}

export interface Address {
  id: string
  city: string
  state: string
  country: string
  line1: string
  line2: string
  zip: string
}

export type PlaceType = google.maps.places.AutocompletePrediction

export const SearchAnalyticsMap = {
  SORT_CTA: 'load_search_sort_click',
  SEARCH_CTA: 'load_search_click',
  MORE_FILTERS_CTA: 'load_search_more_filters_click',
  [SortType.RECOMMENDED]: 'load_search_sort_recommended_click',
  [SortType.REVENUE_PER_HOUR]: 'load_search_sort_rev_hour_click',
  [SortType.BEST_PRICE]: 'load_search_sort_total_price_click',
  [SortType.NEXT_AVAILABLE]: 'load_search_sort_pickup_time_click',
  [SortType.TOTAL_DEADHEAD]: 'load_search_sort_deadhead_click',
  [SortType.RATE_PER_MILE]: 'load_search_sort_rpm_click',
  [SortType.DISTANCE]: 'load_search_sort_distance_click',
  [SortType.WEIGHT]: 'load_search_sort_weight_click',
  [SortType.TIME_POSTED]: 'load_search_sort_time_posted_click',
}

//interfaces for Maps
export interface FindPlaceFromQueryRequest {
  query: string
  fields: string[]
}

export interface AppSettings {
  web_google_api_key?: string
  stripe_publishable_api_key?: string
  stripe_subscription_publishable_api_key?: string
  googleApisLoaded?: boolean
  pusher_key?: string
  pusher_cluster?: string
  feature_flags?: string[]
}

export interface PusherConfig {
  pusherKey: string
  pusherCluster: string
}

export interface AsyncSearchFinishedPayload {
  notes: Record<string, number>
  num_loads_returned: number
  num_loads_total: number
  time_elapsed_seconds: number
}

export enum StepStatus {
  COMPLETED = 'COMPLETED',
  PENDING = 'PENDING',
  ERROR = 'ERROR',
  NONE = 'NONE',
}

export interface DropdownItemLabel {
  primary: string
  secondary: string
}

export const DistanceLabels = [
  { primary: 'Local', secondary: 'Less than 100mi' },
  { primary: 'Short', secondary: '100mi–300mi' },
  { primary: 'Long', secondary: 'Over 300mi' },
]

export interface DropdownItem {
  value: string
  label: string
}

export enum FeasibilityStatus {
  DRIVER_ONBOARDING = 'DRIVER_ONBOARDING',
  DRIVER_BLOCKING_CONFLICT = 'DRIVER_BLOCKING_CONFLICT',
  DRIVER_OTHER_CONFLICT = 'DRIVER_OTHER_CONFLICT',
  ADMIN_DRIVER_CONFLICT = ' ADMIN_DRIVER_CONFLICT',
  ADMIN_CONFLICTING_TIMES_DRIVER = 'ADMIN_CONFLICTING_TIMES_DRIVER',
  LOAD_UNCONFIRMED = 'LOAD_UNCONFIRMED',
  NO_AVAILABLE_DRIVERS = 'NO_AVAILABLE_DRIVERS',
  IS_FEASIBLE = 'IS_FEASIBLE',
  UNDEFINED = 'UNDEFINED',
}

export { ConnectedIntegrationStatusEnum, BusinessType, Environment }
export type {
  FleetApplication,
  ConnectedIntegration,
  ConnectedIntegrationFormAction,
  NotConnectedIntegration,
  Integration,
  ConnectedIntegrationFieldComponent,
}
