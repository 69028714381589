import { useDispatch } from 'react-redux'
import { FlexBox } from 'shared/components/Base/FlexBox'
import { Box } from 'shared/components/Box/Box'
import { Button } from 'shared/components/Button/Button'
import { SimpleDialog } from 'shared/components/Dialog/SimpleDialog'
import { ExternalLink } from 'shared/components/ExternalLink/ExternalLink'
import { Pill } from 'shared/components/Pill/Pill'
import { Text } from 'shared/components/Text/Text'
import { Handler } from 'shared/helpers/typeHelper'
import { useHelpCenterUrl } from 'shared/hooks/useHelpCenterUrl'
import { bgGray, darkGray } from 'shared/theme/colors'
import styled from 'styled-components'

import { SparklesBlack } from '../../components/Icons/Icon'
import { closeConsultant } from '../../redux/actions/consultant/consultant'

interface ConsultantTermsProps {
  onSuccess: Handler
}

export function ConsultantTerms({ onSuccess }: ConsultantTermsProps) {
  const dispatch = useDispatch()
  const learnMoreLink = useHelpCenterUrl('ai-consultant')

  function handleClose() {
    dispatch(closeConsultant())
  }

  return (
    <SimpleDialog isOpen onClose={handleClose}>
      <CircledIcon>
        <img src={SparklesBlack} alt="" />
      </CircledIcon>
      <Text variant="header2" mt={2} align="center">
        <FlexBox alignItems="center" gap="10px" justifyContent="center">
          <span>Ask Cai</span>
          <Pill mb={0.7}>Beta</Pill>
        </FlexBox>
      </Text>
      <Text variant="paragraph1" mt={1} align="left">
        Cai (CloudTrucks AI) is your personal AI business consultant designed to provide general business
        guidance and information to help you run a better and more profitable trucking business. It may
        occasionally contain some errors or inaccuracies. Please keep in mind that it's not meant to replace
        professional advice. The information you share may be recorded to improve our products.{' '}
        <ExternalLink variant="paragraph1" href={learnMoreLink}>
          Learn more
        </ExternalLink>
      </Text>
      <Box m={5} />
      <Button onClick={onSuccess} size="large" margin="0 auto">
        Continue
      </Button>
    </SimpleDialog>
  )
}

const CircledIcon = styled.div`
  width: 64px;
  height: 64px;
  border: 2px solid ${darkGray};
  display: flex;
  align-items: center;
  justify-content: center;
  margin: 0 auto;
  border-radius: 50%;
  background: ${bgGray};
`
