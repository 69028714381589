export enum PusherAsyncSearchEvent {
  PUSHING_LOADS = 'pushing_loads',
  SEARCH_FINISHED = 'search_finished',
  SEARCH_FAILED = 'search_failed',
}

export enum SortType {
  RECOMMENDED = 'RECOMMENDED',
  REVENUE_PER_HOUR = 'REVENUE_PER_HOUR',
  BEST_PRICE = 'BEST_PRICE',
  HIGH_TO_LOW = 'HIGH_TO_LOW',
  LOW_TO_HIGH = 'LOW_TO_HIGH',
  NEXT_AVAILABLE = 'NEXT_AVAILABLE',
  TOTAL_DEADHEAD = 'TOTAL_DEADHEAD',
  RATE_PER_MILE = 'RATE_PER_MILE',
  DISTANCE = 'DISTANCE',
  WEIGHT = 'WEIGHT',
  TIME_POSTED = 'TIME_POSTED',
  TIME_POSTED_ASC = 'TIME_POSTED_ASC',
  TIME_POSTED_DESC = 'TIME_POSTED_DESC',
}
