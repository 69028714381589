import { useEffect, useState } from 'react'
import { useDispatch } from 'react-redux'
import { Box } from 'shared/components/Box/Box'
import { Button } from 'shared/components/Button/Button'
import { ButtonVerticalGroup } from 'shared/components/Button/ButtonVerticalGroup'
import { TaskStatusEnum } from 'shared/components/TaskList/TaskList'
import { useOnboardingChecklist } from 'shared/hooks/query/useOnboardingChecklist'
import { getDrugScreeningUrl } from 'shared/models/backgroundCheck'
import { OnboardingChecklist, OnboardingSubChecklist } from 'shared/models/onboarding'
import { User } from 'shared/models/user'

import { useErrorMessage } from '../../hooks/useErrorMessage'
import { useInfoMessage } from '../../hooks/useInfoMessage'
import { getUserAndTeammateInfo } from '../../redux/actions/users/actions'

interface CheckrOnboardingButtonProps {
  task: OnboardingChecklist | OnboardingSubChecklist
  user: User
}

export function CheckrOnboardingButton({ task, user }: CheckrOnboardingButtonProps) {
  const [viewDetailsLoading, setViewDetailsLoading] = useState(false)
  const infoMessage = useInfoMessage()
  const errorMessage = useErrorMessage()
  const dispatch = useDispatch()
  const { refetch } = useOnboardingChecklist(user.id, {
    enabled: false,
  })

  async function handleViewDetails() {
    try {
      setViewDetailsLoading(true)
      const url = await getDrugScreeningUrl(user.id)
      window.open(url, '_blank')
    } catch (err: any) {
      if (err?.response?.status === 404) {
        infoMessage({ message: `We don't have the details yet. Please try again in few minutes.` })
      } else {
        errorMessage(err)
      }
    }
    setViewDetailsLoading(false)
  }

  useEffect(() => {
    let interval: ReturnType<typeof setTimeout> | undefined = undefined
    if (!user.driver.checkrDrugScreeningAppointmentUrl) {
      interval = setInterval(() => dispatch(getUserAndTeammateInfo()), 5000)
    } else {
      if (interval) clearInterval(interval)
    }
    return () => (interval ? clearInterval(interval) : undefined)
  }, [user.driver.checkrDrugScreeningAppointmentUrl, dispatch])

  useEffect(() => {
    let interval: ReturnType<typeof setTimeout> | undefined = undefined
    if (task.status === TaskStatusEnum.NOT_STARTED) {
      interval = setInterval(() => refetch(), 10000)
    }
    return () => (interval ? clearInterval(interval) : undefined)
  }, [refetch, task.status])

  if (task.status === TaskStatusEnum.NOT_STARTED) {
    return (
      <Button
        size="medium"
        variant="secondary"
        href={user.driver.checkrDrugScreeningAppointmentUrl}
        target="_blank"
        isLoading={!user.driver.checkrDrugScreeningAppointmentUrl}
      >
        Schedule
      </Button>
    )
  }

  if (
    task.status === TaskStatusEnum.IN_PROGRESS &&
    task.statusDetail !== 'SCREENING_COMPLETED' &&
    task.statusDetail !== 'SCREENING_APPOINTMENT_ATTENDED'
  ) {
    return (
      <Box mt={0.5}>
        <ButtonVerticalGroup
          spacing="small"
          leftButton={
            <Button size="medium" variant="text" onClick={handleViewDetails} isLoading={viewDetailsLoading}>
              See instructions
            </Button>
          }
          rightButton={
            <Button
              size="medium"
              variant="text"
              href={user.driver.checkrDrugScreeningAppointmentUrl}
              target="_blank"
            >
              Reschedule
            </Button>
          }
          isStackedOnMobile
        />
      </Box>
    )
  }

  return null
}
