import { detectEnvironmentFromUrl, Environment } from 'shared/config/config'

export function captureEvent(
  event: string,
  metadata: Record<string, any> = {},
  options?: SegmentAnalytics.SegmentOpts
) {
  if (detectEnvironmentFromUrl() !== Environment.PRODUCTION) {
    // eslint-disable-next-line no-console
    console.log(`Tracking analytics event: ${event}, metadata:`, metadata, options)
  }

  // This should be the only usage if this call
  // eslint-disable-next-line no-restricted-syntax
  window.analytics?.track(event, metadata, options)
}
