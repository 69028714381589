import { useEffect } from 'react'
import { FieldValues, UseFormSetError } from 'react-hook-form'

export function setErrors<T extends FieldValues>(
  errors: Record<string, string> | null,
  setError: UseFormSetError<T>
) {
  if (errors) {
    Object.keys(errors).forEach((err) => {
      setError(err as any, { message: errors[err] })
    })
  }
}

export function useSetErrors<T extends FieldValues>(
  errors: Record<string, string> | null,
  setError: UseFormSetError<T>
) {
  useEffect(() => {
    setErrors(errors, setError)
  }, [errors, setError])
}
